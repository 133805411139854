<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="hospitalNm"
            v-if="isVisibleConfirm()"
            label="物件名"
            outlined
            prepend-inner-icon="mdi-hospital-building"
            disabled
          ></v-text-field>
          <v-autocomplete
            v-model="hospitalNm"
            :items="hospitals"
            v-if="!isVisibleConfirm()"
            outlined
            label="物件名"
            item-text="name"
            item-value="code"
            prepend-inner-icon="mdi-hospital-building"
            chips
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <DatePicker
            v-model="date"
            prepend-inner-icon="mdi-calendar"
            outlined
            label="作業実施日付"
            clearable
        /></v-col>
        <v-col class="dialog-padding">
          <label>集合時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="assemblyTime"
            placeholder="集合時間"
            manual-input
            auto-scroll
            input-width="95%"
            :disabled="lock"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="dialog-padding">
          <label>開始時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="startTime"
            placeholder="開始時間"
            manual-input
            auto-scroll
            input-width="95%"
            :disabled="lock"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="dialog-padding">
          <label>終了時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="endTime"
            placeholder="終了時間"
            manual-input
            auto-scroll
            input-width="95%"
            :disabled="lock"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="dialog-padding">
          <label>帰社時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="leaveTime"
            placeholder="帰社時間"
            manual-input
            auto-scroll
            input-width="95%"
            :disabled="lock"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-checkbox v-model="greeting" label="始業前あいさつ"></v-checkbox>
        <v-col class="dialog-padding" cols="1">
          <v-checkbox v-model="safety" label="安全確認"></v-checkbox>
        </v-col>
        <v-col class="dialog-padding" cols="1">
          <v-checkbox v-model="signage" label="表示板"></v-checkbox>
        </v-col>
        <v-col class="dialog-padding" cols="2">
          <v-checkbox v-model="cone" label="コーン + チェーン"></v-checkbox>
        </v-col>
        <v-col class="dialog-padding" cols="2">
          <v-text-field
            v-model="otherThingText"
            label="その他"
            outlined
            prepend-icon="mdi-card-text"
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding" cols="1">
          <v-checkbox
            v-model="chain"
            v-if="false"
            label="チェーン"
          ></v-checkbox>
        </v-col>
        <v-col class="dialog-padding" cols="1">
          <v-checkbox
            v-model="otherThing"
            v-if="false"
            label="その他"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-toolbar color="success" dense>
        <span class="headline white--text"> 作業担当者 </span>
        <v-col class="dialog-padding"></v-col>
        <v-btn color="orange lighten-1" @click="copytanto()"
          >作業担当者・担当者 同日コピー</v-btn
        >
      </v-toolbar>
      <p></p>
      <template v-for="(wk, i) in wktantosha">
        <v-row class="margin-row" :key="i">
          <v-btn class="" color="error" @click="delt(1, i)">削除</v-btn>
          <v-col class="dialog-padding">
            <v-autocomplete
              v-model="wk.code"
              :items="users"
              outlined
              clearable
              :rules="[Rules.Required]"
              prepend-inner-icon="mdi-account"
              label="作業担当者"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model="wk.start"
              label="休憩時間①"
              outlined
              prepend-icon="mdi-numeric"
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model="wk.end"
              label="休憩時間②"
              outlined
              prepend-icon="mdi-numeric"
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="wk.rest"
              label="休憩時間実績"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="H"
              outlined
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-checkbox
              v-model="wk.calc"
              @change="timecalc('1', i)"
              label="時間計算"
            ></v-checkbox>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="wk.work"
              label="作業時間"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="H"
              outlined
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="wk.over"
              label="残業時間"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="H"
              outlined
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <v-col class="dialog-padding">
        <v-btn rounded color="primary" small @click="addt(1)">
          <v-icon>mdi-plus</v-icon>作業担当者追加</v-btn
        >
      </v-col>
      <v-toolbar color="success" dense>
        <span class="headline white--text"> 担当者 </span>
        <v-col class="dialog-padding"></v-col>
        <v-btn color="orange lighten-1" @click="copyworktanto()"
          >作業担当者の実績コピー</v-btn
        >
      </v-toolbar>
      <p></p>
      <template v-for="(wk, i) in tantosha">
        <v-row class="margin-row" :key="wk.code">
          <v-btn class="" color="error" @click="delt(2, i)">削除</v-btn>
          <v-col class="dialog-padding">
            <v-autocomplete
              v-model="wk.code"
              :items="users"
              outlined
              clearable
              :rules="[Rules.Required]"
              prepend-inner-icon="mdi-account"
              label="担当者"
              item-text="name"
              item-value="code"
            ></v-autocomplete>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model="wk.start"
              label="休憩時間①"
              outlined
              prepend-icon="mdi-numeric"
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model="wk.end"
              label="休憩時間②"
              outlined
              prepend-icon="mdi-numeric"
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="wk.rest"
              label="休憩時間実績"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="H"
              outlined
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-checkbox
              v-model="wk.calc"
              @change="timecalc('0', i)"
              label="時間計算"
            >
            </v-checkbox>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="wk.work"
              label="作業時間"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="H"
              outlined
              :disabled="wk.lock"
            ></v-text-field>
          </v-col>
          <v-col class="dialog-padding">
            <v-text-field
              v-model.number="wk.over"
              label="残業時間"
              prepend-icon="mdi-numeric"
              type="number"
              step="0.1"
              suffix="H"
              outlined
              :disabled="wk.lock"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </template>
      <v-col class="dialog-padding">
        <v-btn rounded color="primary" small @click="addt(2)">
          <v-icon>mdi-plus</v-icon>担当者追加</v-btn
        >
      </v-col>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="tantoUpdateInfo"
            label="担当者変更内容"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="tantoUpdateReason"
            label="担当者変更理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <template v-for="meisai in meisais">
        <v-card elevation="5" :key="meisai.packageMeisaiNo">
          <v-toolbar color="primary">
            <v-btn
              class=""
              color="error"
              @click="meisaidel(meisai.packageMeisaiNo)"
              >明細削除</v-btn
            >
          </v-toolbar>
          <p></p>
          <v-row class="margin-row">
            <v-col class="dialog-padding">
              <v-text-field
                v-model="meisai.workPattern"
                label="作業内容"
                outlined
                prepend-inner-icon="mdi-toolbox"
                :rules="[Rules.Required]"
                maxlength="255"
              ></v-text-field>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox v-model="meisai.sepalationflag" label="追加・修正">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="margin-row">
            <v-col class="dialog-padding">
              <v-text-field
                v-model="meisai.floor"
                label="フロア"
                outlined
                prepend-inner-icon="mdi-floor-plan"
              ></v-text-field>
            </v-col>
            <v-col class="dialog-padding">
              <v-text-field
                v-model="meisai.area"
                label="エリア"
                outlined
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            class="margin-row"
            v-for="(item, i) in meisai.workareas"
            :key="i"
          >
            <v-col class="dialog-padding" cols="3">
              <v-text-field
                v-model="item.workarea"
                :label="'作業箇所  ' + (i + 1)"
                outlined
                prepend-icon="mdi-close"
                @click:prepend="clickDelRow(meisai.packageMeisaiNo, i)"
                required
                :rules="[Rules.Required]"
                maxlength="255"
                :disabled="!isDisabledConfirm(item)"
              />
            </v-col>
            <v-col class="dialog-padding" cols="2">
              <v-text-field
                v-model="item.workareazansuu"
                :label="'残数量 ' + (i + 1)"
                type="number"
                outlined
                prepend-icon="mdi-numeric"
                :disabled="!isDisabledConfirm(item) || item.workareasuu == ''"
              />
            </v-col>
            <v-col class="dialog-padding" cols="2">
              <v-autocomplete
                v-model="item.suukbn"
                :items="suuKbns"
                :label="'数量種類 ' + (i + 1)"
                disabled
                clearable
                outlined
                item-text="name"
                item-value="code"
                prepend-icon="mdi-format-list-bulleted"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox
                v-model="item.status"
                label="実施済"
                :disabled="!isDisabledConfirm(item)"
              >
              </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox
                v-model="item.nextmonth"
                label="次月対応"
                :disabled="!isDisabledConfirm(item)"
              >
              </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox
                v-model="item.hold"
                label="日程検討"
                :disabled="!isDisabledConfirm(item)"
              >
              </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox
                v-model="item.nextcycle"
                label="次回サイクル"
                :disabled="!isDisabledConfirm(item)"
              >
              </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox
                v-model="item.sepalate"
                label="追加・修正"
                :disabled="!isDisabledConfirm(item)"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-col class="dialog-padding">
            <v-btn rounded color="primary" small @click="addrow(meisai)">
              <v-icon>mdi-plus</v-icon>作業箇所を追加</v-btn
            >
          </v-col>
          <p></p>
          <p></p>
        </v-card>
      </template>
      <p></p>
      <v-col class="dialog-padding">
        <v-btn rounded color="primary" small @click="addmeisai()">
          <v-icon>mdi-plus</v-icon>明細を追加</v-btn
        >
      </v-col>
      <v-row>
        <v-col class="dialog-padding" cols="3">
          <v-checkbox v-model="request" label="病院側より要請"> </v-checkbox>
          <v-text-field
            v-model="hospitalTanto"
            label="病院担当者"
            outlined
            prepend-icon="mdi-card-text"
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding" cols="2">
          <v-checkbox v-model="me" label="自社都合"> </v-checkbox>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="hospitalremainbiko"
            label="病院側:残理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="remainbiko"
            label="自社都合:残理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <DatePicker
            v-model="schedulereviewdate"
            prepend-icon="mdi-calendar"
            outlined
            label="日程検討期日"
            clearable
        /></v-col>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="addReason"
            label="追加理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="changeReason"
            label="変更理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="biko"
            label="備考"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="comment"
            label="作業報告内容"
            outlined
            prepend-icon="mdi-comment-text"
          ></v-text-field>
        </v-col>
      </v-row> -->
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import PackageinfoResponse from "@/models/PackageinfoResponse";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsMeal = {
  Default: 0,
  Eat: 1
};
export default {
  name: "AttendanceFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: {
    "vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  computed: {
    Title() {
      return "実績編集";
      /*const args = this.args;
      if (args) return "勤務実績追加・修正";
      const date = new Date(args.date);
      return args.name
        ? args.name
        : `${date.getMonth() + 1} 月 ${date.getDate()} 日`; //args.date;*/
    }
  },
  data() {
    return {
      date: "",
      data: {},
      meisais: [],
      wktantosha: [],
      tantosha: [],
      targetYm: "",
      hospitals: [],
      addmode: true,
      keyId: "",
      hospitalNm: "",
      assemblyTime: "",
      startTime: "",
      endTime: "",
      leaveTime: "",
      greeting: false,
      safety: false,
      signage: false,
      cone: false,
      chain: false,
      otherThing: false,
      request: false,
      me: false,
      remainbiko: "",
      biko: "",
      addReason: "",
      changeReason: "",
      hospitalTanto: "",
      lock: false,
      tantoUpdateInfo: "",
      tantoUpdateReason: "",
      scheduledate: "",
      otherThingText: "",
      hospitalremainbiko: "",
      schedulereviewdate: "",
      suuKbns: []
    };
  },
  methods: {
    isDisabledConfirm(item) {
      console.log("isDisabledConfirm", item);
      if (item.display == "1") {
        return true;
      }
      return false;
    },
    addrow(item) {
      console.log("addrow", item);
      item.workareas.push({
        workarea: "",
        workareasuu: "",
        workareazansuu: "",
        status: true,
        add: true,
        display: "1",
        sepalate: true,
        nextmonth: false,
        hold: false,
        nextcycle: false,
        keyid: ""
      });
      //this.workareas.push({ workarea: "", status: false });
    },
    addt(kbn) {
      console.log("addt", kbn);
      if (kbn == 1) {
        this.wktantosha.push({
          code: "",
          start: "",
          end: "",
          rest: "",
          work: "",
          calc: false,
          lock: false
        });
      } else {
        this.tantosha.push({
          code: "",
          start: "",
          end: "",
          rest: "",
          work: "",
          calc: false,
          lock: false
        });
      }
    },
    addmeisai() {
      const addmei = [];
      const wkpat = [""];
      const pacmeiNo = [1];
      //const pacmeiNo = this.meisais.length + 1;
      if (this.meisais.length > 0) {
        wkpat[0] = this.meisais[this.meisais.length - 1].workPattern;
        pacmeiNo[0] =
          parseInt(this.meisais[this.meisais.length - 1].packageMeisaiNo) + 1;
      }

      addmei.push({
        worakarea: "",
        status: true,
        add: true,
        display: "1",
        sepalate: "1",
        nextmonth: false,
        hold: false,
        nextcycle: false,
        keyid: ""
      });
      this.meisais.push({
        packageMeisaiNo: pacmeiNo[0],
        workPattern: wkpat[0],
        floor: "",
        area: "",
        workareas: addmei,
        addflag: "1",
        sepalationflag: "1",
        referencemeisaiid: "",
        processingschedulenextmonth: "",
        processingschedulehold: "",
        processingschedulenextcycle: "",
        meisaiadd: true
      });
    },
    async clickDelRow(No, i) {
      const meisais = this.meisais;
      console.log("meisais", meisais);
      console.log("No", No);
      const absNo = [0];

      for (let j = 0; j < meisais.length; j++) {
        if (meisais[j].packageMeisaiNo == Math.abs(No)) {
          absNo[0] = j;
          break;
        }
      }
      //if (meisais[absNo - 1].workareas.length > 1) {
      if (meisais[absNo[0]].workareas[i].add == true) {
        if (meisais[absNo[0]].workareas[i].workarea) {
          const msg = `作業箇所 ${i + 1} を削除します。よろしいですか？`;
          if (!(await this.$deleteConfirm(msg))) return;
        }
        console.log("RowDelete", meisais[absNo[0]].workareas);
        this.meisais = meisais;
        this.meisais[absNo[0]].workareas.splice(i, 1);
      } else {
        const msg = "追加分で無いため削除できません。";
        this.$error(msg);
      }
      /*const meisais = this.meisais;
      console.log("meisais", meisais);
      console.log("No", No);
      const absNo = Math.abs(No);
      //if (meisais[absNo - 1].workareas.length > 1) {
      if (meisais[absNo - 1].workareas[i].add == true) {
        if (meisais[absNo - 1].workareas[i].workarea) {
          const msg = `作業箇所 ${i + 1} を削除します。よろしいですか？`;
          if (!(await this.$deleteConfirm(msg))) return;
        }
        console.log("RowDelete", meisais[absNo - 1].workareas);
        this.meisais = meisais;
        this.meisais[absNo - 1].workareas.splice(i, 1);
      } else {
        const msg = "追加分で無いため削除できません。";
        this.$error(msg);
      }*/
      //}
    },
    async copytanto() {
      //同日かつ同病院の担当者情報を引用する※実績登録されている場合のみ
      if (this.hospitalNm == undefined || this.hospitalNm == null) {
        this.$error("病院を設定してください。");
        return;
      }
      if (this.date == undefined || this.date == null || this.date == "") {
        this.$error("実施日を設定してください。");
        return;
      }

      if (
        !(await this.$confirm(
          "担当者実績を引用します。\n よろしいですか？\n(内容が上書きされます。)",
          "確認"
        ))
      ) {
        return;
      }

      const hjcd = [""];
      const hpcd = [""];
      if (this.addmode == true) {
        hjcd[0] = this.hospitalNm.substr(0, 2);
        hpcd[0] = this.hospitalNm.substr(2, 3);
      } else {
        hjcd[0] = this.args[0].hojinCd;
        hpcd[0] = this.args[0].hospitalCd;
      }

      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const param = {
        executiondate: year + "-" + ("00" + month).slice(-2) + "-" + day,
        hojin: hjcd[0],
        hospital: hpcd[0],
        keyid: this.keyId
      };
      const encode = encodeURI(JSON.stringify(param));

      const result = await this.$get(this.Paths.tantofind, "query=" + encode);

      console.log("result", result);

      if (result.listtanto == null) {
        alert("該当するデータがありません。");
        return;
      }

      this.lock == false;

      //作業担当者処理用の枠を作成
      this.tantosha = [];
      this.wktantosha = [];
      for (let j = 0; j < result.listtanto.length; j++) {
        const user = result.listtanto[j];
        if (user.mainflag == "1") {
          this.wktantosha.push({
            code: user.userid,
            start: user.resttime1,
            end: user.resttime2,
            rest: user.resttime,
            work: user.worktime,
            over: user.overtime,
            calc: user.calcFlag === "1" ? true : false,
            lock: user.calcFlag === "1" ? true : false
          });
          if (this.lock == false && user.calcFlag === "1") {
            this.lock = true;
          }
        } else {
          this.tantosha.push({
            code: user.userid,
            start: user.resttime1,
            end: user.resttime2,
            rest: user.resttime,
            work: user.worktime,
            over: user.overtime,
            calc: user.calcFlag === "1" ? true : false,
            lock: user.calcFlag === "1" ? true : false
          });
          if (this.lock == false && user.calcFlag === "1") {
            this.lock = true;
          }
        }
      }

      return result;
    },
    async copyworktanto() {
      console.log("作業担当者実績のコピー");
      if (this.wktantosha.length > 0) {
        const wkt = this.wktantosha[0];

        for (let i = 0; i < this.tantosha.length; i++) {
          this.tantosha[i].start = wkt.start;
          this.tantosha[i].end = wkt.end;
          this.tantosha[i].rest = wkt.rest;
          this.tantosha[i].work = wkt.work;
          this.tantosha[i].over = wkt.over;
          this.tantosha[i].calc = wkt.calc;
          this.tantosha[i].lock = wkt.lock;
        }
      }
    },
    async delt(kbn, i) {
      if (kbn == 1) {
        //作業担当者の処理
        const msg = "作業担当者を削除してよろしいですか？";
        if (!(await this.$deleteConfirm(msg))) return;
        console.log("wktantoDelete", this.wktantosha[i]);
        this.wktantosha.splice(i, 1);
      } else {
        //担当者の処理
        const msg = "担当者を削除してよろしいですか？";
        if (!(await this.$deleteConfirm(msg))) return;
        console.log("tantoDelete", this.tantosha[i]);
        this.tantosha.splice(i, 1);
      }
    },
    async meisaidel(packageNo) {
      const absNo = [0];
      const meisais = this.meisais;
      //追加した明細だけを削除する。
      for (let i = 0; i < meisais.length; i++) {
        if (meisais[i].packageMeisaiNo == Math.abs(packageNo)) {
          absNo[0] = i;
          break;
        }
      }
      if (meisais[absNo[0]].addflag == "1") {
        const msg = `明細 ${absNo[0] + 1} を削除します。よろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
        this.meisais.splice(absNo[0], 1);
      } else {
        const msg = "追加分で無いため削除できません。";
        this.$error(msg);
      }
      /*const absNo = Math.abs(packageNo);
      const meisais = this.meisais;
      //追加した明細だけを削除する。
      if (meisais[absNo - 1].addflag == "1") {
        const msg = `明細 ${absNo} を削除します。よろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
        this.meisais.splice(absNo - 1, 1);
      } else {
        const msg = "追加分で無いため削除できません。";
        this.$error(msg);
      }*/
    },
    async timecalc(kbn, i) {
      //開始時間、終了時間、休憩時間が全て入力されていれば計算処理を実行する。
      console.log("timecalc", kbn);

      if (kbn == 1) {
        console.log(this.wktantosha[i].rest);
        if (this.startTime != "" && this.endTime != "") {
          if (this.wktantosha[i].calc == false) {
            this.wktantosha[i].lock = false;
          } else {
            const starttime = this.startTime.split(":");
            const endtime = this.endTime.split(":");
            const resttime =
              this.wktantosha[i].rest == "" ? 0 : this.wktantosha[i].rest;

            let H = Number(endtime[0]) - Number(starttime[0]);
            let m = Number(endtime[1]) - Number(starttime[1]);
            let MinusH = 0;
            while (m < 0) {
              m = m + 60;
              MinusH = MinusH - 1;
            }

            H = H + MinusH;
            m = m / 60;
            const wktime = H + m - resttime;
            this.wktantosha[i].work = wktime;
            if (wktime > 7.5) {
              this.wktantosha[i].over = wktime - 7.5;
            } else {
              this.wktantosha[i].over = 0;
            }
            this.wktantosha[i].lock = true;
          }
        }
      } else {
        if (this.startTime != "" && this.endTime != "") {
          if (this.tantosha[i].calc == false) {
            this.tantosha[i].lock = false;
          } else {
            const starttime = this.startTime.split(":");
            const endtime = this.endTime.split(":");
            const resttime =
              this.tantosha[i].rest == "" ? 0 : this.tantosha[i].rest;

            let H = Number(endtime[0]) - Number(starttime[0]);
            let m = Number(endtime[1]) - Number(starttime[1]);
            let MinusH = 0;
            while (m < 0) {
              m = m + 60;
              MinusH = MinusH - 1;
            }

            H = H + MinusH;
            m = m / 60;
            const wktime = H + m - resttime;
            this.tantosha[i].work = wktime;
            if (wktime > 7.5) {
              this.tantosha[i].over = wktime - 7.5;
            } else {
              this.tantosha[i].over = 0;
            }
            this.tantosha[i].lock = true;
          }
        }
      }

      //時間のロック処理
      let lockflag = false;

      for (let i = 0; i < this.wktantosha.length; i++) {
        if (this.wktantosha[i].calc == true) {
          lockflag = true;
          break;
        }
      }

      for (let i = 0; i < this.tantosha.length; i++) {
        if (lockflag == true) {
          break;
        }
        if (this.tantosha[i].calc == true) {
          lockflag = true;
        }
      }

      this.lock = lockflag;
    },
    shozokuSelect() {
      console.log("selected", this.shozoku);
      const shozoku = this.shozoku;
      const filtershifts = this.recordShifts.filter(
        e => e.refcode === shozoku || e.refcode === CommonShiftRefCode
      );
      this.shifts = filtershifts;
    },
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        console.log(set);
        if (!this.edit) {
          this.data.start = set.option1;
          this.data.end = set.option2;
          this.data.breaktime = set.option3;
        }
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "0";
        }
      }
    },
    isVisibleConfirm() {
      if (this.addmode == true) {
        return false;
      } else {
        return true;
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");
      try {
        this.$loading();
        const args = this.args;

        console.log("args", args);
        console.log("meisais", this.meisais);
        const PackageValue = []; // 登録用パッケージ情報
        const PackageMeisaiValue = []; // 登録用明細情報
        const addplace = [""]; // 追加箇所一覧
        const remain = ["0"]; // 残箇所の判定用
        const srdflag = ["0"]; // 日程検討の存在フラグ
        const ncflag = ["0"]; // 次回サイクルの存在フラグ
        const addchangeflag = ["0"]; // 追加・変更のチェックがあるかどうかの判定用

        console.log("Hp", this.hospitalNm);

        //物件名は、必須
        if (this.hospitalNm == undefined || this.hospitalNm == "") {
          this.$error("物件を選択してください。");
          return;
        }

        //作業日のチェック
        if (this.date == undefined || this.date == "" || this.date == null) {
          this.$error("作業日を設定してください。");
          return;
        }

        //明細の行数チェック
        if (this.meisais.length == 0) {
          this.$error("明細は、１つ以上設定してください。");
          return;
        }

        const remainplace = [""]; // 残箇所一覧
        //明細ごとでループして、登録用の作業箇所を作成
        for (let i = 0; i < this.meisais.length; i++) {
          //作業箇所のリストを作成する。
          const meisai = this.meisais[i];
          const sepalateflag = ["0"];

          //作業内容は、必須
          if (meisai.workPattern == undefined || meisai.workPattern == "") {
            this.$error(
              "明細番号" + (i + 1) + "の作業内容が入力されていません。"
            );
            return;
          }

          //作業箇所を設定する
          const Workarea = [];
          const meisaikey = [""];
          for (let j = 0; j < meisai.workareas.length; j++) {
            const wkarea = meisai.workareas[j]; //作業箇所
            const sepalate = ["0"]; //色フラグ
            const add = ["0"]; //追加フラグ
            const status = ["0"]; //ステータス
            const display = meisai.workareas[j].display;
            const zansuu = [0]; //フィルター清掃等の残数

            // 追加・修正の状態を取得
            if (wkarea.sepalate == true) {
              sepalate[0] = "1";
              // 色フラグ = 追加・変更フラグの判定を入れる
              if (addchangeflag[0] === "0") {
                addchangeflag[0] = "1";
              }
            }

            // 実施済の状態を取得
            if (wkarea.status == true) {
              status[0] = "1";
            } else {
              // 未実施の場合は、表示フラグの状態をチェック
              if (display == "1") {
                // 処理対象で未実施 = 残とする
                if (remainplace[0] == "") {
                  remainplace[0] = wkarea.workarea;
                } else {
                  remainplace[0] = remainplace[0] + ", " + wkarea.workarea;
                }
              }
            }

            // 追加された箇所かどうかをチェック
            if (wkarea.add == true) {
              add[0] = "1";
              // 追加箇所の場合は、追加箇所の一覧を作成
              if (addplace[0] != "") {
                addplace[0] = wkarea.workarea;
              } else {
                addplace[0] = addplace[0] + ", " + wkarea.workarea;
              }
            }
            //作業箇所は、必須項目
            if (wkarea.workarea == undefined || wkarea.workarea == "") {
              this.$error(
                "作業箇所" + (j + 1) + "の作業箇所に未入力があります。"
              );
              return;
            }
            //残理由の処理
            const psnm = ["0"];
            const psh = ["0"];
            const psnc = ["0"];
            // 次月対応
            if (wkarea.nextmonth == true) {
              psnm[0] = "1";
            }
            // 予定日未定
            if (wkarea.hold == true) {
              psh[0] = "1";
            }
            // 次回サイクル
            if (wkarea.nextcycle == true) {
              psnc[0] = "1";
            }

            //残理由と説明は、セットになる
            if (
              status[0] == "0" &&
              psnm[0] == "0" &&
              psh[0] == "0" &&
              psnc[0] == "0" &&
              wkarea.display == "1"
            ) {
              this.$error(
                "作業箇所" +
                  (j + 1) +
                  "の実施済にチェックが無い場合は、理由のいずれかにチェックを入れていください。"
              );
              return;
            } else if (
              status[0] == "1" &&
              (psnm[0] == "1" || psh[0] == "1" || psnc[0] == "1")
            ) {
              this.$error(
                "作業箇所" +
                  (j + 1) +
                  "の実施済にチェックが有る場合は、理由にチェックを入れないでください。"
              );
              return;
            }

            // フィルター清掃などの残数の処理
            zansuu[0] = wkarea.workareazansuu;
            if (wkarea.display == "1") {
              if (wkarea.workareasuu != undefined && wkarea.workareasuu != "") {
                if (
                  wkarea.workareazansuu == undefined ||
                  wkarea.workareazansuu == ""
                ) {
                  if (status[0] == "1") {
                    //実施済みの場合は、こちらで0を入れる
                    zansuu[0] = 0;
                  } else {
                    this.$error(
                      "作業箇所" +
                        (j + 1) +
                        "の残数量に数字を入力してください。"
                    );
                    return;
                  }
                } else if (wkarea.workareazansuu != 0 && status[0] == "1") {
                  // 完了で残数はありえないので、チェックする
                  this.$error(
                    "作業箇所" +
                      (j + 1) +
                      "は、完了しているので、残数量を0にしてください。"
                  );
                  return;
                }
              }
            }

            // 数量と数量種類は、対となる
            console.log("check_suryo");
            let suukbn = "";
            if (wkarea.suukbn != "") {
              if (wkarea.suukbn != null && wkarea.suukbn.code != undefined) {
                suukbn = wkarea.suukbn.code;
              } else if (wkarea.suukbn != null) {
                suukbn = wkarea.suukbn;
              } else {
                suukbn = "";
              }
            }

            //登録用の作業箇所を作成
            Workarea.push({
              workarea: wkarea.workarea,
              workareaSuu: wkarea.workareasuu,
              workareaZansuu: zansuu[0],
              workareaSuuKbn: suukbn,
              sepalationflag: sepalate[0],
              displayflag: wkarea.display,
              status: status[0],
              addflag: add[0],
              referenceworkareaid: wkarea.keyid,
              processingschedulenextmonth: psnm,
              processingschedulehold: psh,
              processingschedulenextcycle: psnc,
              processingscheduleother: wkarea.other
            });

            //残がある場合は、残理由の判定用にセットする。
            if (
              psnm[0] == "1" ||
              psh[0] == "1" ||
              psnc[0] == "1" ||
              wkarea.display == "0"
            ) {
              remain[0] = "1";
            }

            // 日程検討期日の必須入力チェックの判定用フラグをセット
            if (psh[0] == "1" && srdflag[0] == "0") {
              srdflag[0] = "1";
            }

            // 次回サイクルの存在チェック
            if (ncflag[0] == "0" && psnc[0] == "1") {
              ncflag[0] = "1";
            }

            console.log("workare_ref", wkarea.keyid);
          }

          // 明細情報の追加フラグを処理
          const meiadd = ["0"];
          if (meisai.meisaiadd == true) {
            meiadd[0] = "1";
          }

          // 明細情報の追加・変更フラグを処理
          if (meisai.sepalationflag == true) {
            sepalateflag[0] = "1";
            if (addchangeflag[0] === "0") {
              addchangeflag[0] = "1";
            }
          }

          //明細情報を格納
          PackageMeisaiValue.push({
            packageMeisaiNo: meisai.packageMeisaiNo,
            workpattern: meisai.workPattern,
            area: meisai.area,
            floor: meisai.floor,
            workarea: Workarea,
            addflag: meiadd[0],
            sepalationflag: sepalateflag[0],
            referencemeisaiid: "",
            meisaiadd: meisai.meisaiadd
          });
        }

        // 作業箇所の追加フラグ
        const addplflag = ["0"];

        // 追加フラグが空欄で無い場合は、追加フラグを立てる
        if (addplace[0] !== "") {
          addplflag[0] = "1";
        }

        console.log("user", this.user);

        // 担当者情報を格納する
        const wktanto = []; // 作業担当者
        const tantochk = []; // 担当者
        console.log("wktantosha", this.wktantosha);
        // 作業担当者の処理
        if (this.wktantosha != undefined || this.wktantosha != null) {
          for (let k = 0; k < this.wktantosha.length; k++) {
            if (this.wktantosha[k] == undefined) {
              continue;
            }
            if (tantochk.includes(this.wktantosha[k].code)) {
              this.$error("作業担当者、担当者で重複が存在します。");
              return;
            }
            tantochk.push(this.wktantosha[k].code);
            wktanto.push({
              userid: this.wktantosha[k].code,
              username: "",
              resttime1: this.wktantosha[k].start,
              resttime2: this.wktantosha[k].end,
              worktime: this.wktantosha[k].work,
              resttime: this.wktantosha[k].rest,
              overtime: this.wktantosha[k].over,
              calcFlag: this.wktantosha[k].calc === true ? "1" : "0"
            });
          }
        }

        const tanto = [];
        console.log("tantosha", this.tantosha);
        // 担当者の処理
        if (this.tantosha != undefined || this.tantosha != null) {
          for (let m = 0; m < this.tantosha.length; m++) {
            if (this.tantosha[m] == undefined) {
              continue;
            }
            if (tantochk.includes(this.tantosha[m].code)) {
              this.$error("作業担当者、担当者で重複が存在します。");
              return;
            }
            tantochk.push(this.tantosha[m].code);
            tanto.push({
              userid: this.tantosha[m].code,
              username: "",
              resttime1: this.tantosha[m].start,
              resttime2: this.tantosha[m].end,
              worktime: this.tantosha[m].work,
              resttime: this.tantosha[m].rest,
              overtime: this.tantosha[m].over,
              calcFlag: this.tantosha[m].calc === true ? "1" : "0"
            });
          }
        }

        const packNo = ["0"];
        const hjcd = [""];
        const hpcd = [""];
        const hpnm = [""];
        const ym = [""];

        // 新規と更新処理の場合に処理を分岐
        if (this.addmode == true) {
          // 追加モードの場合は、スポットのみのため設定情報から取得する
          packNo[0] = 0;
          hjcd[0] = this.hospitalNm.substr(0, 2);
          hpcd[0] = this.hospitalNm.substr(2, 3);
          ym[0] = this.date;
        } else {
          // 更新の場合は、引渡した情報を利用する ※キーにもなるので変更は、困る
          packNo[0] = args[0].packageNo;
          hjcd[0] = args[0].hojinCd;
          hpcd[0] = args[0].hospitalCd;
          hpnm[0] = args[0].hospitalNm;
          ym[0] = this.targetYm;
        }

        // 残理由の病院都合、自社都合の処理
        // 病院都合のみの場合は「1」
        // 自社都合のみの場合は「2」
        // 両方の場合は「1,2」とする
        const rereson = [""];
        if (this.request == true) {
          rereson[0] = "1,";
        } else {
          rereson[0] = "0,";
        }
        if (this.me == true) {
          rereson[0] = rereson[0] + "2";
        } else {
          rereson[0] = rereson[0] + "0";
        }

        // 残箇所と残理由はペアで登録が必要
        if (remain[0] == "1" && rereson[0] == "0,0") {
          this.$error("残箇所がある場合は、残理由を選択してください。");
          return;
        } else if (remain[0] == "0" && rereson[0] != "0,0") {
          this.$error("残理由は、残箇所がある場合のみ有効です。");
          return;
        }

        console.log("rereson", rereson[0]);
        console.log("hospitalTanto", this.hospitalTanto);
        console.log("hospitalremainbiko", this.hospitalremainbiko);
        console.log("remainbiko", this.remainbiko);

        // 各、理由は内容も必須とする
        if (
          (rereson[0] == "0,0" || rereson[0] == "0,2") &&
          this.hospitalTanto != undefined &&
          this.hospitalTanto != ""
        ) {
          this.$error("病院担当者名は、残理由が病院様都合の場合のみ有効です。");
          return;
        }
        if (
          (rereson[0] == "1,0" || rereson[0] == "1,2") &&
          (this.hospitalTanto == undefined ||
            this.hospitalTanto == "" ||
            this.hospitalTanto == null ||
            this.hospitalremainbiko == undefined ||
            this.hospitalremainbiko == "" ||
            this.hospitalremainbiko == null)
        ) {
          this.$error(
            "病院都合の場合は、病院担当者名と病院側の残理由が必須です。"
          );
          return;
        }
        if (
          (rereson[0] == "0,0" || rereson[0] == "0,2") &&
          this.hospitalremainbiko != ""
        ) {
          this.$error("病院側残理由は、病院様都合の場合のみ有効です。");
          return;
        }
        if (
          (rereson[0] == "0,2" || rereson[0] == "1,2") &&
          (this.remainbiko == undefined ||
            this.remainbiko == "" ||
            this.remainbiko == null)
        ) {
          this.$error("自社都合の場合は、自社都合の残理由が必須です。");
          return;
        }
        if (
          (rereson[0] == "0,0" || rereson[0] == "1,0") &&
          this.remainbiko != undefined &&
          this.remainbiko != "" &&
          this.remainbiko != null
        ) {
          this.$error("自社残理由は、自社都合の場合のみ有効です。");
          return;
        }

        // 以下の条件の入力チェックを追加 2022/10/14
        // 追加・変更にチェックがついている場合は、追加理由 or 変更理由に入力必須
        if (addchangeflag[0] === "1") {
          if (
            (this.addReason == undefined || this.addReason == "") &&
            (this.changeReason == undefined || this.changeReason == "")
          ) {
            this.$error(
              "追加・変更にチェックが入っている場合は、追加理由または変更理由の入力が必要です。"
            );
            return;
          }
        }

        // 日程検討期日の追加に伴う、入力チェックを追加 2022/12/21
        console.log("日程検討フラグ", srdflag[0]);
        console.log("日次検討期日", this.schedulereviewdate);
        if (srdflag[0] === "1") {
          if (
            this.schedulereviewdate == undefined ||
            this.schedulereviewdate == null ||
            this.schedulereviewdate == ""
          ) {
            this.$error(
              "日程検討にチェックがある場合は、\n日程検討期日の入力が必要です。"
            );
            return;
          } else {
            // 日付の妥当性チェック 2022/12/21時点 MAX：制限なし、MIN：作業実施日の次の日
            console.log("this.date", this.date);
            console.log("this.schedulereviewdate", this.schedulereviewdate);
            const dt = [""];
            const srdt = [""];
            dt[0] = this.date;
            srdt[0] = this.schedulereviewdate;
            const dtlst = dt[0].split("-");
            const srdtlst = srdt[0].split("-");
            console.log("dt", dt[0]);
            console.log("dt", srdt[0]);
            console.log("dtlst", dtlst[0] + dtlst[1] + dtlst[2]);
            console.log("srdtlst", srdtlst[0] + srdtlst[1] + srdtlst[2]);
            if (
              dtlst[0] + dtlst[1] + dtlst[2] >=
              srdtlst[0] + srdtlst[1] + srdtlst[2]
            ) {
              this.$error(
                "日程検討の期日は、\n作業実施日よりも後の日付にしてください。"
              );
              return;
            }

            /*if (this.date >= this.schedulereviewdate) {
              this.$error(
                "日程検討の期日は、\n作業実施日よりも後の日付にしてください。"
              );
              return;
            }*/
          }
        } else {
          if (
            this.schedulereviewdate != undefined &&
            this.schedulereviewdate != null &&
            this.schedulereviewdate != ""
          ) {
            const confirm = await this.$confirm(
              "日程検討の作業箇所がない場合は、日程検討期日の入力を無視します。\n よろしいですか？"
            );
            if (confirm != true) {
              this.$info("処理を中止します。");
              return;
            } else {
              this.schedulereviewdate = "";
            }
          }
        }

        // 次回サイクルが存在しているかつスポットの場合は、ここでストップ
        if (ncflag[0] === "1" && Number(packNo[0]) <= 0) {
          this.$error(
            "スポット作業の場合は、次回サイクルを設定できません。\n必要な場合は、パッケージ登録をお願いします。"
          );
          return;
        }

        const assemb = this.assemblyTime;
        /*const st = this.startTime;
        const en = this.endTime;*/
        const leave = this.leaveTime;

        const setassemb = [""];
        const setleave = [""];

        // 集合時間と帰社時間の整形
        if (
          assemb != undefined &&
          assemb.HH != null &&
          assemb.HH != undefined
        ) {
          setassemb[0] = this.assemblyTime.HH + ":" + this.assemblyTime.mm;
        } else if (
          this.assemblyTime != null &&
          this.assemblyTime != undefined
        ) {
          setassemb[0] = this.assemblyTime;
        } else {
          setassemb[0] = null;
        }
        if (leave != undefined && leave.HH != null && leave.HH != undefined) {
          setleave[0] = this.leaveTime.HH + ":" + this.leaveTime.mm;
        } else if (this.leaveTime != null && this.leaveTime != undefined) {
          setleave[0] = this.leaveTime;
        } else {
          setleave[0] = null;
        }

        PackageValue.push({
          packageNo: packNo[0],
          startTime: "",
          endTime: "",
          worktanto: wktanto,
          tanto: tanto,
          packagemeisai: PackageMeisaiValue,
          biko: this.biko,
          scheduledate: this.scheduledate,
          executiondate: this.date,
          addplaceflag: addplflag[0],
          addplace: addplace[0],
          remainflag: "",
          remainplace: remainplace[0],
          remainreson: rereson,
          remainbiko: this.remainbiko,
          assemblytime: setassemb[0],
          executionstarttime: this.startTime,
          executionendtime: this.endTime,
          leavetime: setleave[0],
          keyid: this.keyId,
          greeting: this.greeting === true ? "1" : "0",
          safety: this.safety === true ? "1" : "0",
          signage: this.signage === true ? "1" : "0",
          cone: this.cone === true ? "1" : "0",
          chain: this.chain === true ? "1" : "0",
          otherThing: this.otherThing === true ? "1" : "0",
          otherThingText: this.otherThingText,
          hospitalTanto: this.hospitalTanto,
          addReason: this.addReason,
          changeReason: this.changeReason,
          hospitalremainbiko: this.hospitalremainbiko,
          tantoUpdateInfo: this.tantoUpdateInfo,
          tantoUpdateReason: this.tantoUpdateReason,
          schedulereviewdate: this.schedulereviewdate
        });

        console.log("store", this.$store.state.userincode);

        // ヘッダの格納
        const Packagehed = [];
        Packagehed.push({
          hojinCd: hjcd[0],
          hospitalCd: hpcd[0],
          hospitalNm: hpnm[0],
          yearMonth: ym[0],
          details: PackageValue,
          loginId: this.$store.state.userincode
        });

        const param = new PackageinfoResponse(Packagehed);
        const result = await this.$post(this.Paths.schedulefix, param);

        console.log("result", result);

        //if (result) {
        await this.$info("更新しました。", "予定");
        console.log("complete end");
        this.$close(true);
        //}
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getUsers() {
      console.log("getUsers");
      return await this.$get(this.Paths.shainFilter);
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    },
    async getkeydata(key) {
      const param = {
        keyid: key
      };
      const encode = encodeURI(JSON.stringify(param));

      return await this.$get(this.Paths.schedulefind, "query=" + encode);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let f = [];
      f = this.codeMasterItems.filter(e => e.refcode === CodeGroup.SUUKBN);
      this.suuKbns = f.filter(e => e.code !== NotSetCode.SUUKBN);
    }
  },
  async created() {
    this.meisais = [];
    console.log("created", this.args);

    await this.getCodeMasterItems();
    this.getPullDownItems();

    // 参照データが無い場合は、処理を終了する
    if (this.args == undefined) {
      return;
    }

    const args = this.args; // 参照データ
    const users = await this.getUsers(); // 社員情報
    this.hospitals = await this.getHospital(); // 病院情報
    this.users = users;

    const ListNo = [0]; // 参照データの格納位置リスト
    //呼出元に応じてセットする内容を変更
    //作業未了一覧からの遷移の場合は、格納位置が代わるので位置情報を設定
    for (let i = 1; i < 12; i++) {
      let pushnum = i;
      if (args[0].unfinished != true) {
        // 実績管理画面から遷移した場合
        if (i == 1) {
          pushnum = i + 1;
        } else {
          pushnum = i + 2;
        }
        ListNo.push(pushnum);
      } else {
        // 未了一覧から遷移した場合
        if (i >= 3 && i <= 6) {
          pushnum = i + 6;
        } else if (i >= 7 && i <= 9) {
          pushnum = i - 4;
        } else if (i >= 10 && i <= 11) {
          pushnum = i + 3;
        }
        ListNo.push(pushnum);
      }
    }

    if (args[0].unfinished == true && args[0].yotei == "3") {
      //未了一覧から遷移かつ繰越分の実績を処理
      const result = await this.getkeydata(args[0].keyId); //データの取得
      const hedder = result.hedder[0]; // ヘッダ
      this.hospitalNm = hedder.hospitalName; // 病院情報
      const packages = hedder.details; // 詳細情報
      const meisai = packages[0].packagemeisai; // 明細情報

      // 明細情報をループして、内容を設定する
      for (let i = 0; i < meisai.length; i++) {
        const row = meisai[i];
        const mei = row.workarea;

        const workareas = [];
        // 作業箇所情報の設定
        for (let k = 0; k < mei.length; k++) {
          const wkarea = mei[k];
          let check = false; // 状態フラグ
          let add = false; // 追加フラグ(画面上は、非表示)
          let sep = false; // 追加・変更フラグ
          let nm = false; // 次月対応フラグ
          let hd = false; // 次回未定フラグ
          let nc = false; // 次回サイクルフラグ
          let ot = "0"; // 別作業フラグ
          let disp = "0"; // 画面表示フラグ
          // 状態の設定
          if (wkarea.status != null && wkarea.status == "1") {
            check = true;
          }
          // 追加フラグの設定
          if (wkarea.addflag != null && wkarea.addflag == "1") {
            add = true;
          }
          // 追加・変更フラグの設定
          if (wkarea.sepalationflag != null && wkarea.sepalateflag == "1") {
            sep = true;
          }
          // 画面表示フラグの設定
          if (wkarea.displayflag != null && wkarea.displayflag == "1") {
            disp = "1";
          }
          // 次月対応の設定
          if (
            wkarea.processingschedulenextmonth != null &&
            wkarea.processingschedulenextmonth == "1"
          ) {
            nm = true;
          }
          // 次回未定フラグの設定
          if (
            wkarea.processingschedulehold != null &&
            wkarea.processingschedulehold == "1"
          ) {
            hd = true;
          }
          // 次回サイクルフラグの設定
          if (
            wkarea.processingschedulenextcycle != null &&
            wkarea.processingschedulenextcycle == "1"
          ) {
            nc = true;
          }
          // 別作業フラグの設定
          if (
            wkarea.processingscheduleother != null &&
            wkarea.processingscheduleother == "1"
          ) {
            ot = "1";
          }
          // 作業箇所情報の格納
          workareas.push({
            workarea: wkarea.workarea,
            workareasuu: wkarea.workareaSuu,
            workareazansuu: wkarea.workareaZansuu,
            suukbn: wkarea.workareaSuuKbn,
            status: check,
            add: add,
            sepalate: sep,
            display: disp,
            nextmonth: nm,
            hold: hd,
            nextcycle: nc,
            other: ot,
            keyid: wkarea.referenceworkareaid
          });
        }

        // 明細情報の格納
        this.meisais.push({
          packageMeisaiNo: row.packageMeisaiNo,
          workPattern: row.workpattern,
          floor: row.floor,
          area: row.area,
          workareas: workareas,
          addflag: row.addflag,
          sepalationflag: row.sepalationflag === "1" ? true : false,
          referencemeisaiid: "",
          processingschedulenextmonth: "",
          processingschedulehold: "",
          processingschedulenextcycle: "",
          meisaiadd: false
        });
      }

      // 作業担当、担当者情報の設定
      const tanto = packages[0].tanto;
      const wktanto = packages[0].worktanto;

      for (let l = 0; l < tanto.length; l++) {
        this.tantosha.push({
          code: tanto[l].userid,
          start: tanto[l].resttime1,
          end: tanto[l].resttime2,
          rest: tanto[l].resttime,
          work: tanto[l].worktime,
          calc: tanto[l].calcFlag === "1" ? true : false,
          lock: tanto[l].calcFlag === "1" ? true : false
        });

        if (this.lock == false && tanto[l].calcFlag === "1") {
          this.lock = true;
        }
      }

      for (let l = 0; l < wktanto.length; l++) {
        this.wktantosha.push({
          code: wktanto[l].userid,
          start: wktanto[l].starttime,
          end: wktanto[l].endtime,
          rest: wktanto[l].resttime,
          work: wktanto[l].worktime,
          calc: wktanto[l].calcFlag === "1" ? true : false,
          lock: wktanto[l].calcFlag === "1" ? true : false
        });

        if (this.lock == false && wktanto[l].calcFlag === "1") {
          this.lock = true;
        }
      }

      // 各項目の設定
      this.startTime = packages[0].startTime;
      this.endTime = packages[0].endTime;
      this.biko = packages[0].biko;
      this.addReason = packages[0].addReason;
      this.changeReason = packages[0].changeReason;

      this.targetYm = hedder.yearMonth;

      this.keyId = args[0].keyId; // 更新用のキー情報

      this.addmode = false;

      this.scheduledate = packages[0].scheduledate;
    } else {
      // 実績管理から遷移した場合の処理
      //const meisais = [args.length];
      //パッケージ明細の数だけ格納する。
      for (let i = 0; i < args.length; i++) {
        const row = args[i]; // 予定情報(Schedule_Package)
        const mei = row.packagemeisais; // 明細情報
        const workareaList = mei[ListNo[9]].name.split(","); // 作業箇所情報 ※遷移した場合は、カンマ区切りの為分解
        const workareasuus = mei[ListNo[9]].suu.split(","); //作業箇所の数値
        const workareazansuus = mei[ListNo[9]].zansuu.split(","); //作業箇所の数値
        const Workareakbns = mei[ListNo[9]].kbn.split(","); // 作業箇所の数値区分
        const workstatus = mei[ListNo[9]].status.split(","); // 状態
        const workadd = mei[ListNo[9]].code.split(","); // 追加フラグ
        const sepalatef = mei[ListNo[9]].sepalate.split(","); // 追加・変更歩フラグ
        const displst = mei[ListNo[9]].display.split(","); // 画面表示フラグ
        const nextm = mei[ListNo[9]].nextmonth.split(","); // 次月対応フラグ
        const hld = mei[ListNo[9]].hold.split(","); // 次回未定フラグ
        const nextc = mei[ListNo[9]].nextcycle.split(","); // 次回サイクル
        const key = mei[ListNo[9]].keyid.split(","); // キー情報
        const refid = mei[ListNo[9]].refid.split(","); // 参照キー情報
        const psother = mei[ListNo[9]].other.split(","); // 別作業フラグ
        const workareas = [];

        // 作業箇所リスト
        for (let j = 0; j < workareaList.length; j++) {
          let check = false; //状態
          let add = false; // 追加
          let sep = false; // 追加・変更
          let nm = false; // 次月
          let hd = false; // 次回未定
          let nc = false; // 次回サイクル
          let ot = "0"; // 別作業
          // 状態の設定
          if (workstatus[j] === "1") {
            if (args[0].unfinished != true) {
              check = true;
            }
          }
          // 追加フラグの設定
          if (workadd[j] == "1") {
            add = true;
          }
          // 追加・変更の設定
          if (sepalatef[j] == "1") {
            sep = true;
          }
          // 次月対応の設定
          if (nextm[j] == "1") {
            nm = true;
          }
          // 日程検討の設定
          if (hld[j] == "1") {
            hd = true;
          }
          // 次回サイクル
          if (nextc[j] == "1") {
            nc = true;
          }
          // 別作業の設定
          if (psother[j] == "1") {
            ot = "1";
          }
          // 作業箇所の格納
          workareas.push({
            workarea: workareaList[j],
            workareasuu: workareasuus[j],
            workareazansuu: workareazansuus[j],
            suukbn: Workareakbns[j],
            status: check,
            add: add,
            sepalate: sep,
            nextmonth: nm,
            hold: hd,
            nextcycle: nc,
            other: ot,
            display: displst[j],
            keyid: refid[j]
          });
        }
        // 明細情報の設定
        this.meisais.push({
          packageMeisaiNo: row.packageMeisaiNo,
          workPattern: mei[ListNo[1]].name,
          floor: mei[ListNo[7]].name,
          area: mei[ListNo[8]].name,
          workareas: workareas,
          addflag: mei[ListNo[11]].addflag,
          sepalationflag: mei[ListNo[11]].sepalationflag === "1" ? true : false,
          referencemeisaiid: "",
          processingschedulenextmonth: "",
          processingschedulehold: "",
          processingschedulenextcycle: "",
          meisaiadd: mei[ListNo[11]].addflag
        });
      }

      this.hospitalNm = args[0].packagemeisais[ListNo[0]].name; //物件名

      const user = [];
      const tantoList = args[0].packagemeisais[ListNo[5]].code.split(",");
      const tantoexec = args[0].packagemeisais[ListNo[5]].status;
      const tantoList2 = args[0].packagemeisais[ListNo[6]].code.split(",");
      const tantoexec2 = args[0].packagemeisais[ListNo[6]].status;

      //作業担当者処理用の枠を作成
      this.wktantosha = [];
      if (
        tantoList2.length > 1 ||
        (tantoList2.length === 1 && tantoList2[0] !== "")
      ) {
        for (let j = 0; j < tantoList2.length; j++) {
          if (args[0].unfinished != true) {
            //user.push(tantoList[j]); //担当者
            const timelst = tantoexec2[j].split(",");
            for (let k = 0; k < timelst.length; k++) {
              if (timelst[k] == "null") {
                timelst[k] = "";
              }
            }
            this.tantosha.push({
              code: tantoList2[j],
              start: timelst[0],
              end: timelst[1],
              rest: timelst[2],
              work: timelst[3],
              over: timelst[4],
              calc: timelst[5] === "1" ? true : false,
              lock: timelst[5] === "1" ? true : false
            });

            if (this.lock == false && timelst[5] === "1") {
              this.lock = true;
            }
          } else {
            this.tantosha.push({
              code: tantoList2[j],
              start: "",
              end: "",
              rest: "",
              work: "",
              over: "",
              calc: false,
              lock: false
            });
          }
        }
      }

      if (
        tantoList.length > 1 ||
        (tantoList.length === 1 && tantoList[0] !== "")
      ) {
        for (let j = 0; j < tantoList.length; j++) {
          if (args[0].unfinished != true) {
            //user2.push(tantoList2[j]); //担当者
            const timelst = tantoexec[j].split(",");
            for (let k = 0; k < timelst.length; k++) {
              if (timelst[k] == "null") {
                timelst[k] = "";
              }
            }
            this.wktantosha.push({
              code: tantoList[j],
              start: timelst[0],
              end: timelst[1],
              rest: timelst[2],
              work: timelst[3],
              over: timelst[4],
              calc: timelst[5] === "1" ? true : false,
              lock: timelst[5] === "1" ? true : false
            });

            if (this.lock == false && timelst[5] === "1") {
              this.lock = true;
            }
          } else {
            this.wktantosha.push({
              code: tantoList[j],
              start: "",
              end: "",
              rest: "",
              work: "",
              over: "",
              calc: false,
              lock: false
            });
          }
        }
      }

      // 日付を設定
      if (args[0].unfinished != true) {
        if (args[0].packagemeisais[ListNo[2]].name != undefined) {
          if (args[0].packagemeisais[ListNo[2]].name != null) {
            const sdate = args[0].packagemeisais[ListNo[2]].name.split("/");
            if (sdate.length == 3) {
              this.date = sdate[0] + "-" + sdate[1] + "-" + sdate[2];
            } else {
              this.date = args[0].packagemeisais[ListNo[2]].name;
            }
          }
        }
      }

      // 集合時間の設定
      this.assemblyTime = args[0].assemblytime;
      if (args[0].unfinished != true) {
        this.startTime = args[0].packagemeisais[ListNo[3]].name;
        this.endTime = args[0].packagemeisais[ListNo[4]].name;
      } else {
        this.startTime = "";
        this.endTime = "";
      }
      // 帰社時間の設定
      this.leaveTime = args[0].leavetime;
      // 備考の設定
      this.biko = args[0].packagemeisais[ListNo[10]].name;
      // 対象年月の設定
      this.targetYm = args[0].packagemeisais[ListNo[0]].status;
      // key_Idの設定
      this.keyId = args[0].keyId;

      // あいさつの設定
      if (
        args[0].greeting != null &&
        args[0].greeting != undefined &&
        args[0].greeting == "1"
      ) {
        this.greeting = true;
      }
      // 安全確認の設定
      if (
        args[0].safety != null &&
        args[0].safety != undefined &&
        args[0].safety == "1"
      ) {
        this.safety = true;
      }
      // 掲示板の設定
      if (
        args[0].signage != null &&
        args[0].signage != undefined &&
        args[0].signage == "1"
      ) {
        this.signage = true;
      }
      // コーンの設定
      if (
        args[0].cone != null &&
        args[0].cone != undefined &&
        args[0].cone == "1"
      ) {
        this.cone = true;
      }
      // チェーンの設定
      if (
        args[0].chain != null &&
        args[0].chain != undefined &&
        args[0].chain == "1"
      ) {
        this.chain = true;
      }
      // その他の設定
      if (
        args[0].otherThing != null &&
        args[0].otherThing != undefined &&
        args[0].otherThing == "1"
      ) {
        this.otherThing = true;
      }
      // その他の内容設定
      if (
        args[0].otherThingText != null &&
        args[0].otherThingText != undefined
      ) {
        this.otherThingText = args[0].otherThingText;
      }
      // 追加理由
      if (args[0].addReason != null && args[0].addReason != undefined) {
        this.addReason = args[0].addReason;
      }
      // 変更理由
      if (
        (args[0].changeReason != null) &
        (args[0].changeReason != undefined)
      ) {
        this.changeReason = args[0].changeReason;
      }
      // 病院担当者
      if (args[0].hospitalTanto != null && args[0].hospitalTanto != undefined) {
        this.hospitalTanto = args[0].hospitalTanto;
      }
      // 病院側理由
      if (
        args[0].hospitalremainbiko != null &&
        args[0].hospitalremainbiko != undefined
      ) {
        this.hospitalremainbiko = args[0].hospitalremainbiko;
      }
      // 担当者変更情報
      if (
        args[0].tantoUpdateInfo != null &&
        args[0].tantoUpdateInfo != undefined
      ) {
        this.tantoUpdateInfo = args[0].tantoUpdateInfo;
      }
      // 担当者変更理由
      if (
        args[0].tantoUpdateReason != null &&
        args[0].tantoUpdateReason != undefined
      ) {
        this.tantoUpdateReason = args[0].tantoUpdateReason;
      }
      // 予定日の設定(ここで設定しておかないと、登録時に予定日が消えるバグが発生するため)
      if (args[0].scheduledate != null && args[0].scheduledate != undefined) {
        this.scheduledate = args[0].scheduledate;
      }

      // 残理由を設定
      const res = Number(args[0].packagemeisais[ListNo[11]].remainreson);
      switch (res) {
        case 1:
          this.request = true;
          break;
        case 2:
          this.me = true;
          break;
        case 3:
          this.request = true;
          this.me = true;
          break;
      }

      // 備考
      this.remainbiko = args[0].packagemeisais[ListNo[11]].remainbiko;

      // 日程検討期日
      if (args[0].schedulereviewdate != "") {
        const sdate = args[0].schedulereviewdate.split("/");
        if (sdate.length == 3) {
          this.schedulereviewdate = "";
          console.log("reviedate", sdate[0] + "-" + sdate[1] + "-" + sdate[2]);
          this.schedulereviewdate = sdate[0] + "-" + sdate[1] + "-" + sdate[2];
        }
      }
      /*if (args[0].unfinished != true) {
        if (args[0].packagemeisais[ListNo[2]].name != undefined) {
          if (args[0].packagemeisais[ListNo[2]].name != null) {
            const sdate = args[0].packagemeisais[ListNo[2]].name.split("/");
            if (sdate.length == 3) {
              this.date = sdate[0] + "-" + sdate[1] + "-" + sdate[2];
            } else {
              this.date = args[0].packagemeisais[ListNo[2]].name;
            }
          }
        }
      }*/

      // 病院情報の有無で追加モードかどうかを判定する。
      if (this.hospitalNm != undefined) {
        if (this.hospitalNm != "") {
          this.addmode = false;
        }
      }
    }
    console.log("meisais", this.meisais);
  }
};
</script>

<style scoped>
label {
  font-size: 1em;
}
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
input[type="checkbox"] {
  transform: scale(2);
}
</style>
