<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    close
    @clickClose="close(false)"
  >
    <v-card class="pa-5" flat outlined>
      <v-autocomplete
        v-model="selecthospital"
        :items="hospitals"
        outlined
        label="病院選択"
        item-text="name"
        item-value="code"
        chips
      ></v-autocomplete>
      <v-row style="height: 80px;">
        <v-col cols="5">
          <DatePicker
            v-model="scdateFrom"
            label="作業予定日From"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
            reactive
          />
        </v-col>
        <v-col cols="1"> <p class="range-text">〜</p></v-col>
        <v-col cols="5">
          <DatePicker
            v-model="scdateTo"
            label="作業予定日To"
            prepend-inner-icon="mdi-calendar"
            type="day"
            outlined
            reactive
          />
        </v-col>
      </v-row>
      <v-row style="height: 30px;"> </v-row>
      <v-text-field
        v-model="work"
        label="作業内容"
        outlined
        prepend-inner-icon="mdi-transit-connection-variant"
        maxlength="255"
      ></v-text-field>
      <v-text-field
        v-model="floor"
        label="フロア"
        outlined
        prepend-inner-icon="mdi-transit-connection-variant"
        maxlength="255"
      ></v-text-field>
      <v-text-field
        v-model="area"
        label="エリア"
        outlined
        prepend-inner-icon="mdi-home-map-marker"
        maxlength="255"
      ></v-text-field>
      <v-text-field
        v-model="workarea"
        label="作業箇所"
        outlined
        prepend-inner-icon="mdi-close"
      />
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="spotclick('point')">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!!schedule && schedule.length" class="office mt-5">
      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="packagerow in affiliation.packages[n - 1]">
              <tr :key="packagerow.rowindex">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <Btn
                    color="info"
                    text
                    small
                    @click="
                      reference(affiliation.index, packagerow.index, 'query')
                    "
                    >参照</Btn
                  >
                </th>
                <template v-for="meisai in packagerow.packagemeisais">
                  <td
                    :key="meisai.rowindex"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    style="white-space: pre-line; word-wrap: break-word;"
                    :class="meisai.width"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-01";
const Users = [];

export default {
  name: "SpotreferecnceDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: {
    //"vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  computed: {
    Title() {
      return "スポット履歴選択";
    },
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  data() {
    return {
      date:
        DATE.getFullYear() +
        "-" +
        ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(DATE.getDate())).slice(-2),
      data: {},
      scdateFrom: "",
      scdateTo: "",
      meisais: [],
      wktantosha: [],
      tantosha: [],
      targetYm: "",
      hospitals: [],
      addmode: true,
      schedule: [],
      affiliations: [],
      selecthospital: { code: "", name: "" },
      workaffiliation: [],
      work: "",
      floor: "",
      area: "",
      workarea: ""
    };
  },
  methods: {
    rowStyleClass(row) {
      console.log("rowStyleClass", row);
      return row.remarks === "1" ? "yellow" : "";
    },
    async confirm() {
      this.isConfirm = await this.$confirm("よろしいですか？");
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async spotclick(pathparam) {
      console.log("click", pathparam);
      console.log("this.scdateFrom", this.scdateFrom);
      console.log("this.scdateTo", this.scdateTo);
      console.log("this.selecthoslital", this.selecthospital);
      //console.log("date", this.date);
      //const date = new Date(this.date);
      const result = true;

      if (this.selecthospital == null || this.selecthospital == undefined) {
        this.$error("病院を選択してください。");
        return;
      }

      if (
        (this.selecthospital.code == undefined &&
          this.selecthospital.name == "") ||
        (this.selecthospital.code == "" && this.selecthospital.name == "")
      ) {
        this.$error("病院を選択してください。");
        return;
      }

      const f = this.scdateFrom.split("-");
      const t = this.scdateTo.split("-");

      if (Number(f[0]) > Number(t[0])) {
        this.$error("作業予定日のToは、Fromと同日か未来日を指定してください。");
        return;
      } else if (Number(f[0]) == Number(t[0])) {
        if (Number(f[1]) > Number(t[1])) {
          this.$error(
            "作業予定日のToは、Fromと同日か未来日を指定してください。"
          );
          return;
        } else if (Number(f[1]) == Number(t[1])) {
          if (Number(f[2]) > Number(t[2])) {
            this.$error(
              "作業予定日のToは、Fromと同日か未来日を指定してください。"
            );
            return;
          }
        }
      }

      if (result) {
        this.$loading();
        try {
          this.workaffiliation = [];
          this.schedule = await this.getschedule(pathparam);
          console.log("schedule", this.schedule);
          this.affiliations = this.workaffiliation;
          if (this.affiliations.length == 0) {
            alert("該当するデータがありません。");
            return;
          }
          //this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getschedule(pathparam) {
      console.log("getShiftMonth", pathparam);
      // 選択した所属を取得
      console.log("selected", this.selecthospital);
      this.affiliations = [];
      //this.packages.packagemeisais = [];
      this.packages = [];
      this.schedule = [];
      const hospital = [""];
      if (this.selecthospital.code != undefined) {
        hospital[0] = this.selecthospital.code;
      } else {
        hospital[0] = this.selecthospital;
      }
      console.log(hospital[0]);
      const param = {
        affiliations: hospital[0],
        work: this.work,
        floor: this.floor,
        area: this.area,
        workarea: this.workarea,
        targetmonth: "",
        scheduleflag: "1",
        unfinished: "0",
        select: "1",
        scdatefrom: this.scdateFrom,
        scedateto: this.scdateTo
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "予定データ取得");
      } else {
        return await this.setschedule(result);
      }
    },
    async setschedule(apidata) {
      console.log("setschedule", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;
      console.log("schedulehedder", shedder);

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        schedules.push({
          index: Number(shedder[i].code),
          name: shedder[i].name
        });
      }

      //
      const hedder = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        console.log("affiliations", this.affiliations);
        const rowscount = [0];
        const rowindex = [0];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          const bgcolor = [""];
          //行の色設定

          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          const tanto = [""];
          const tantonm = [""];
          const wtanto = [""];
          const wtantonm = [""];

          //担当者のセット
          for (let d = 0; d < c.tanto.length; d++) {
            if (d > 0) {
              tanto[0] = tanto[0] + ",";
              tantonm[0] = tantonm[0] + ",\r\n";
            }
            tanto[0] = tanto[0] + c.tanto[d].userid;
            tantonm[0] = tantonm[0] + c.tanto[d].username;
          }

          //作業担当者のセット
          for (let e = 0; e < c.worktanto.length; e++) {
            if (e > 0) {
              wtanto[0] = wtanto[0] + ",";
              wtantonm[0] = wtantonm[0] + ",\r\n";
            }
            wtanto[0] = wtanto[0] + c.worktanto[e].userid;
            wtantonm[0] = wtantonm[0] + c.worktanto[e].username;
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const stime = c.startTime.split(":");
            const etime = c.endTime.split(":");

            const prows = [0];

            if (k == 0) {
              prows[0] = meisairowspan;
            }

            //物件名～フロアまでは、固定
            //物件名
            if (j > 0 || k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: rowscount[0],
                status: "",
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                width: "seven",
                color: bgcolor[0]
              });
            }
            //作業年月
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: "",
              width: "five",
              color: bgcolor[0]
            });
            //委託先
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.company,
              width: "five",
              color: bgcolor[0]
            });
            //作業内容
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              width: "seven",
              color: bgcolor[0]
            });
            //作業日時
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.scheduledate,
              width: "seven",
              color: bgcolor[0]
            });
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                width: "five",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0]
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                width: "five",
                color: bgcolor[0]
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                width: "five",
                color: bgcolor[0]
              });
            }
            //作業担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: wtanto[0],
              sepalate: "",
              name: wtantonm[0],
              width: "six",
              color: bgcolor[0]
            });
            //担当者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: tanto[0],
              sepalate: "",
              name: tantonm[0],
              width: "six",
              color: bgcolor[0]
            });
            //フロア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.floor,
              width: "seven",
              color: bgcolor[0]
            });
            //エリア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.area,
              width: "seven",
              color: bgcolor[0]
            });
            const warea = [""];
            const wareasuu = [""];
            const wareakbn = [""];
            const st = [""];
            const add = [""];
            const sep = [""];
            const key = [""];
            const ref = [""];
            const other = [""];
            const nextc = [""];
            const allncflag = [true];
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                wareasuu[0] = wareasuu[0] + ",";
                wareakbn[0] = wareakbn[0] + ",";
                st[0] = st[0] + ",";
                add[0] = add[0] + ",";
                sep[0] = sep[0] + ",";
                key[0] = key[0] + ",";
                ref[0] = ref[0] + ",";
                other[0] = other[0] + ",";
                nextc[0] = nextc[0] + ",";
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              st[0] = st[0] + s.workarea[l].displayflag;
              wareasuu[0] = wareasuu[0] + s.workarea[l].workareaSuu;
              wareakbn[0] = wareakbn[0] + s.workarea[l].workareaSuuKbn;
              add[0] = add[0] + s.workarea[l].addflag;
              sep[0] = sep[0] + s.workarea[l].sepalationflag;
              key[0] = key[0] + s.workarea[l].keyid;
              ref[0] = ref[0] + s.workarea[l].referenceworkareaid;
              other[0] = other[0] + s.workarea[l].processingscheduleother;
              if (
                s.workarea[l].processingschedulenextcycle != null &&
                s.workarea[l].processingschedulenextcycle != undefined
              ) {
                nextc[0] = nextc[0] + s.workarea[l].processingschedulenextcycle;

                if (
                  s.workarea[l].processingschedulenextcycle == "0" &&
                  allncflag[0] == true
                ) {
                  allncflag[0] = false;
                }
              } else {
                nextc[0] = nextc[0] + "0";
              }
            }
            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: st[0],
              code: add[0],
              sepalate: sep[0],
              name: warea[0],
              keyid: key[0],
              referenceid: ref[0],
              width: "workarea",
              color: bgcolor[0],
              other: other[0],
              suu: wareasuu[0],
              kbn: wareakbn[0],
              nextcycle: nextc[0]
            });
            //備考
            packagemeisaiList.push({
              rowspan: prows[0],
              status: "",
              code: "",
              sepalate: "",
              name: c.biko,
              width: "fifteen",
              color: bgcolor[0]
            });
            //その他引渡が必要な項目
            packagemeisaiList.push({
              rowspan: 0,
              addflag: s.addflag,
              sepalationflag: s.sepalationflag,
              referencemeisaiid: "",
              processingschedulenextmonth: "",
              processingschedulehold: "",
              processingschedulenextcycle: "",
              processingscheduleother: other[0],
              width: "",
              color: bgcolor[0],
              weekday: c.weekday
            });

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan,
                unfinished: false,
                keyId: "",
                yotei: "",
                select: "0"
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0,
                unfinished: false,
                keyId: "",
                yotei: "",
                select: "0"
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
          console.log("packages", this.packages);
        }

        const scounts = packages.length;

        this.workaffiliation.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      //this.scount = this.packages.length;
      console.log("scount", this.scount);

      const schedule = schedules;
      console.log("affiliations", this.affiliations);

      return schedule;
    },
    async reference(aindex, pindex, pathparam) {
      console.log("reference", aindex + "-" + pindex);
      const packs = this.affiliations[aindex].packages[pindex];
      console.log(packs);

      const props = { args: packs };
      console.log(props);

      this.$close(props);
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    }
  },
  async created() {
    //console.log("created", this.date);

    this.$loading();

    try {
      const args = this.args;
      console.log("args", this.args);
      this.schedule = args[0].schedule;
      this.affiliations = args[0].affiliations;
      console.log("schedule", this.schedule);
      console.log("affiliations", this.affiliations);
      this.hospitals = await this.getHospital();
      this.selecthospital.code = args[0].affiliations;

      //当月末日を作成
      let dt1;
      let dt2;
      if (DATE.getMonth() == 11) {
        dt1 = new Date(DATE.getFullYear() + 1, 0, 0);
      } else {
        dt1 = new Date(DATE.getFullYear(), DATE.getMonth() + 1, 0);
      }

      //11ヶ月前の1日を取得
      if (dt1.getMonth() == 11) {
        dt2 = new Date(dt1.getFullYear(), 0, 1);
      } else {
        dt2 = new Date(dt1.getFullYear() - 1, dt1.getMonth() + 1, 1);
      }
      this.scdateFrom =
        dt2.getFullYear() +
        "-" +
        ("00" + Number(dt2.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(dt2.getDate())).slice(-2);
      this.scdateTo =
        dt1.getFullYear() +
        "-" +
        ("00" + Number(dt1.getMonth() + 1)).slice(-2) +
        "-" +
        ("00" + Number(dt1.getDate())).slice(-2);
      //this.date = args[0].date;
      //await this.anytimeclick('query');
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style lang="scss" scoped>
.office {
  table {
    width: 99%;
    border-collapse: collapse;
    display: block;
  }
}
.five {
  width: 5%;
}
.six {
  width: 6%;
}
.seven {
  width: 7%;
}
.fifteen {
  width: 11%;
}
.workarea {
  width: 20%;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    width: 100%;
  }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}
</style>
