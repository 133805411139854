<template>
  <div>
    <h1>作業未了一覧</h1>
    <v-card outlined class="mb-5">
      <v-expansion-panels flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span class="headline">検索条件</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col class="dialog-padding" cols="1" v-if="auth > 1">
                <v-checkbox v-model="noschedule" label="予定日無"></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1" v-if="auth > 1">
                <v-checkbox
                  cols="1"
                  v-model="nextmonth"
                  label="次月対応"
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1" v-if="auth > 1">
                <v-checkbox
                  cols="1"
                  v-model="hold"
                  label="日程検討"
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1" v-if="auth > 1">
                <v-checkbox
                  cols="1"
                  v-model="nextcycle"
                  label="次ｻｲｸﾙ"
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1" v-if="auth > 1">
                <v-checkbox
                  cols="1"
                  v-model="otherwork"
                  label="別作業"
                ></v-checkbox>
              </v-col>
              <v-col class="dialog-padding" cols="1">
                <v-checkbox
                  cols="1"
                  v-model="noexecution"
                  label="作業日無"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-actions class="mx-2">
        <v-spacer></v-spacer>
        <Btn color="info" icon="file-import" @click="click('query')">取得</Btn>
      </v-card-actions>
    </v-card>
    <v-card v-if="!!schedule && schedule.length" class="office mt-5">
      <v-toolbar color="primary">
        <!-- <v-btn class="mx-2" color="success" @click="add()"
          >スポット作業スケジュール追加</v-btn
        > -->
      </v-toolbar>
      <template v-for="affiliation in affiliations">
        <table class="mt-4 mx-4" border="1" :key="affiliation.index">
          <thead>
            <tr>
              <th rowspan="1" class="fixed"></th>
              <th v-for="(cal, i) in schedule" :key="i">
                {{ cal.name }}
              </th>
            </tr>
          </thead>
          <template v-for="n in affiliation.scount">
            <template v-for="packagerow in affiliation.packages[n - 1]">
              <tr :key="packagerow.rowindex">
                <th v-if="packagerow.rowspan > 0" :rowspan="packagerow.rowspan">
                  <Btn
                    color="info"
                    text
                    small
                    v-if="packagerow.yotei == '0'"
                    @click="
                      Schedulefix(
                        affiliation.index,
                        packagerow.index,
                        'query',
                        false
                      )
                    "
                    >予定追加</Btn
                  >
                  <Btn
                    color="deep-orange"
                    text
                    small
                    v-if="packagerow.yotei == '1'"
                    @click="
                      Servicefix(affiliation.index, packagerow.index, 'query')
                    "
                    >実績編集</Btn
                  >
                  <Btn
                    color="grey darken-2"
                    text
                    small
                    v-if="packagerow.yotei == '2'"
                    >－</Btn
                  >
                  <Btn
                    color="green"
                    text
                    small
                    v-if="packagerow.yotei == '3'"
                    @click="
                      Servicefix(affiliation.index, packagerow.index, 'query')
                    "
                    >実績編集</Btn
                  >
                  <Btn
                    color="info"
                    text
                    small
                    v-if="packagerow.yotei == '4'"
                    @click="
                      Schedulefix(
                        affiliation.index,
                        packagerow.index,
                        'query',
                        true
                      )
                    "
                    >予定編集</Btn
                  >
                </th>
                <template v-for="meisai in packagerow.packagemeisais">
                  <td
                    :key="meisai.rowindex"
                    v-if="meisai.rowspan > 0"
                    :rowspan="meisai.rowspan"
                    :class="meisai.width"
                    :bgcolor="meisai.color"
                  >
                    {{ meisai.name }}
                  </td>
                </template>
              </tr>
            </template>
          </template>
        </table>
      </template>
    </v-card>
  </div>
</template>

<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import moment from "moment";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";

import Api from "@/mixins/api";
import * as Dummy from "./dummy";
import ScheduleFixDialog from "./dialogs/ScheduleFixDialog.vue";
import ServiceRecord from "./dialogs/ServiceRecordFixDialog.vue";

const ViewTypes = [{ text: "月", value: "month" }];

const Selects = {
  User: 0,
  Office: 1,
  Me: 2
};

const DATE = new Date();
const DATENOW =
  DATE.getFullYear() +
  "-" +
  ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
  "-" +
  ("00" + Number(DATE.getDate())).slice(-2);
const Users = [];
export default {
  name: "ScheduleMaintenance",
  mixins: [Common, ShowDialogs, Api],
  components: {
    //"vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  data: () => ({
    menu: false,
    date:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    dateFrom: DATENOW,
    dateTo: DATENOW,
    select: Selects.User,
    records: [],
    isConfirm: false,
    attendances: { rows: [], columns: [] },
    summaries: { rows: [], columns: [] },
    total: { rows: [], columns: [] },
    cols: [],
    rows: [],
    summaryheader: [],
    summary: [],
    users: Users,
    user: Users[0],
    Selects,
    options: {
      select: {
        enabled: true,
        selectOnCheckboxOnly: true,
        disableSelectInfo: true
      },
      search: { enabled: true }
    },
    selected: [],
    shozokus: [],
    shozoku: { code: "", name: "" },
    backForCalc: false,
    backForShift: false,
    backForApprovalStatus: false,
    showConfirm: false,
    hospitals: [],
    selecthospital: { code: "", name: "" },
    schedule: [],
    auth: ""
  }),
  computed: {
    SummaryHeader() {
      return null;
    },
    Year() {
      return !this.date ? undefined : parseInt(this.date.split("-")[0]);
    },
    Month() {
      return !this.date ? undefined : parseInt(this.date.split("-")[1]);
    },
    Day() {
      if (!this.date) return undefined;
      const split = this.date.split("-");

      return split.length < 2 ? undefined : parseInt(split[2]);
    }
  },
  methods: {
    rowStyleClass(row) {
      console.log("rowStyleClass", row);
      return row.remarks === "1" ? "yellow" : "";
    },
    async getTargetMonth() {
      const startmonth = new Date(this.Year, this.Month, 21);
      let targetdt = new Date(this.Year, this.Month, this.Day);

      const m = moment(targetdt);
      if (targetdt == startmonth) {
        // targetdt.setMonth(targetdt.getMonth() + 1);
        targetdt = m.add(1, "M").toDate();
      } else if (targetdt > startmonth && targetdt.getDate() < 21) {
        //targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      } else if (targetdt < startmonth && targetdt.getDate() < 21) {
        // targetdt.setMonth(targetdt.getMonth() - 1);
        targetdt = m.add(-1, "M").toDate();
      }

      let targetMonth =
        targetdt.getFullYear() +
        "-" +
        ("00" + Number(targetdt.getMonth())).slice(-2);

      if (Number(targetdt.getMonth()) == 0) {
        const m2 = moment(targetdt);
        targetdt = m2.add(-1, "M").toDate();
        targetMonth =
          targetdt.getFullYear() +
          "-" +
          ("00" + Number(targetdt.getMonth() + 1)).slice(-2);
      }

      return targetMonth;
    },
    async confirm() {
      this.isConfirm = await this.$confirm(
        "勤務表を提出します。\nよろしいですか？"
      );
    },
    async click(pathparam) {
      console.log("click", pathparam);
      const date = new Date(this.date);
      const result = true;

      if (result) {
        this.$loading();
        try {
          this.schedule = [];
          this.schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam
          );
          console.log("schedule", this.schedule);
          this.tableTitle = this.format;
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getShiftMonth(year, month, pathparam) {
      console.log("getShiftMonth", year, month, pathparam);
      // 選択した所属を取得
      console.log("selected", this.selecthospital);
      if (
        this.noexecution == false &&
        this.noschedule == false &&
        this.hold == false &&
        this.nextmonth == false &&
        this.nextcycle == false &&
        this.otherwork == false
      ) {
        this.$error("いずれかの条件は、設定してください。");
        return;
      }

      this.affiliations = [];
      //this.packages.packagemeisais = [];
      this.packages = [];
      this.schedules = [];

      const param = {
        affiliations: "",
        targetmonth: "",
        scheduleflag: "1",
        unfinished: "1",
        pattern: this.noexecution == true ? "1" : "0",
        noschedule: this.noschedule == true ? "1" : "0",
        holdschedule: this.hold == true ? "1" : "0",
        nextmonth: this.nextmonth == true ? "1" : "0",
        nextcycle: this.nextcycle == true ? "1" : "0",
        otherschedule: this.otherwork == true ? "1" : "0",
        ambiguous: "0"
      };
      const encode = encodeURI(JSON.stringify(param));
      console.log("encode", encode);
      console.log("this.packages", this.packages);
      // API送信
      const result = await this.$get(
        this.Paths.schedule,
        pathparam + "=" + encode
      );

      console.log("api_result", result);
      if (result.length == 0) {
        await this.$warning("該当するデータがありません。", "未了データ取得");
      } else {
        return await this.setShiftCalendar(result, year, month);
      }
    },
    async setShiftCalendar(apidata, year, month) {
      console.log("setShiftCalendar", apidata);

      //データヘッダを作成
      const shedder = apidata.schedulehedder;
      console.log("schedulehedder", shedder);

      const schedules = [];
      for (let i = 0; i < shedder.length; i++) {
        schedules.push({
          index: Number(shedder[i].code),
          name: shedder[i].name
        });
      }

      //
      const hedder = apidata.hedder;

      for (let i = 0; i < hedder.length; i++) {
        const affiliation = hedder[i];

        const packages = [];
        console.log("affiliations", this.affiliations);
        const rowscount = [0];
        const rowindex = [0];

        for (let a = 0; a < affiliation.details.length; a++) {
          const b = affiliation.details[a];
          rowscount[0] = rowscount[0] + b.packagemeisai.length;
        }

        for (let j = 0; j < affiliation.details.length; j++) {
          const c = affiliation.details[j];

          const bgcolor = [""];
          //期日フラグが入っていない場合は、色を付ける
          if (c.ternmoverflag == "1") {
            bgcolor[0] = "yellow";
          }
          const packageList = [];
          const meisairowspan = c.packagemeisai.length;

          const tanto = [""];
          const tantonm = [""];
          const wtanto = [""];
          const wtantonm = [""];
          const rtanto = [""];
          const rtantonm = [""];

          //担当者情報
          for (let d = 0; d < c.tanto.length; d++) {
            if (d > 0) {
              tanto[0] = tanto[0] + ",";
              tantonm[0] = tantonm[0] + ",";
            }
            tanto[0] = tanto[0] + c.tanto[d].userid;
            tantonm[0] = tantonm[0] + c.tanto[d].username;
          }

          //作業担当者情報
          for (let e = 0; e < c.worktanto.length; e++) {
            if (e > 0) {
              wtanto[0] = wtanto[0] + ",";
              wtantonm[0] = wtantonm[0] + ",";
            }
            wtanto[0] = wtanto[0] + c.worktanto[e].userid;
            wtantonm[0] = wtantonm[0] + c.worktanto[e].username;
          }

          for (let e = 0; e < c.reftanto.length; e++) {
            if (e > 0) {
              rtanto[0] = rtanto[0] + ",";
              rtantonm[0] = rtantonm[0] + ",";
            }
            if (c.reftanto[e].username != null) {
              rtanto[0] = rtanto[0] + c.reftanto[e].userid;
              rtantonm[0] = rtantonm[0] + c.reftanto[e].username;
            }
          }

          for (let k = 0; k < c.packagemeisai.length; k++) {
            const s = c.packagemeisai[k];
            const packagemeisaiList = [];
            const stime = c.startTime.split(":");
            const etime = c.endTime.split(":");

            const prows = [0];

            if (k == 0) {
              prows[0] = meisairowspan;
            }

            //物件名～フロアまでは、固定
            //物件名
            if (j > 0 || k > 0) {
              packagemeisaiList.push({
                rowspan: 0,
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                color: bgcolor[0],
                width: "common"
              });
            } else {
              packagemeisaiList.push({
                rowspan: rowscount[0],
                status: year + "-" + ("00" + month).slice(-2),
                code: "",
                sepalate: "",
                name: affiliation.hospitalName,
                color: bgcolor[0],
                width: "common"
              });
            }
            //作業年月
            if (c.scheduleMonth == null) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: "",
                color: bgcolor[0],
                width: "common"
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: c.scheduleMonth,
                color: bgcolor[0],
                width: "common"
              });
            }
            //作業内容
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.workpattern,
              color: bgcolor[0],
              width: "common"
            });
            //作業日時
            if (c.executiondate == null && c.scheduledate != null) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: c.scheduledate,
                color: bgcolor[0],
                width: "common"
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: "",
                code: "",
                sepalate: "",
                name: c.executiondate,
                color: bgcolor[0],
                width: "common"
              });
            }

            //フロア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.floor,
              color: bgcolor[0],
              width: "common2"
            });
            //エリア
            packagemeisaiList.push({
              rowspan: 1,
              status: "",
              code: "",
              sepalate: "",
              name: s.area,
              color: bgcolor[0],
              width: "common2"
            });
            const warea = [""];
            const wareasuu = [""];
            const wareazansuu = [""];
            const wareakbn = [""];
            const disp = [""];
            const st = [""];
            const add = [""];
            const sep = [""];
            const psm = ["0"];
            const psh = ["0"];
            const psc = ["0"];
            const pso = ["0"];
            const otherflag = [""];
            const key = [""];
            const nextmonth = ["0"];
            const nexthold = ["0"];
            const nextcycle = ["0"];
            const refid = [""];
            for (let l = 0; l < s.workarea.length; l++) {
              if (l > 0) {
                warea[0] = warea[0] + ",";
                wareasuu[0] = wareasuu[0] + ",";
                wareazansuu[0] = wareazansuu[0] + ",";
                wareakbn[0] = wareakbn[0] + ",";
                disp[0] = disp[0] + ",";
                st[0] = st[0] + ",";
                add[0] = add[0] + ",";
                sep[0] = sep[0] + ",";
                psm[0] = psm[0] + ",";
                psh[0] = psh[0] + ",";
                psc[0] = psc[0] + ",";
                key[0] = key[0] + ",";
                refid[0] = refid[0] + ",";
                otherflag[0] = otherflag[0] + ",";
              }
              warea[0] = warea[0] + s.workarea[l].workarea;
              wareasuu[0] = wareasuu[0] + s.workarea[l].workareaSuu;
              wareazansuu[0] = wareazansuu[0] + s.workarea[l].workareaZansuu;
              wareakbn[0] = wareakbn[0] + s.workarea[l].workareaSuuKbn;
              disp[0] = disp[0] + s.workarea[l].displayflag;
              st[0] = st[0] + s.workarea[l].displayflag;
              add[0] = add[0] + s.workarea[l].addflag;
              sep[0] = sep[0] + s.workarea[l].sepalationflag;
              key[0] = key[0] + s.workarea[l].keyid;
              refid[0] = refid[0] + s.workarea[l].referenceworkareaid;
              if (s.workarea[l].processingschedulenextmonth == "1") {
                psm[0] = psm[0] + "1";
                nextmonth[0] = "1";
              } else {
                psm[0] = psm[0] + "0";
              }
              if (s.workarea[l].processingschedulehold == "1") {
                psh[0] = psh[0] + "1";
                nexthold[0] = "1";
              } else {
                psh[0] = psh[0] + "0";
              }
              if (s.workarea[l].processingschedulenextcycle == "1") {
                psc[0] = psc[0] + "1";
                nextcycle[0] = "1";
              } else {
                psc[0] = psc[0] + "0";
              }
              if (
                s.workarea[l].processingscheduleother == "1" &&
                c.otherworkflag == "1"
              ) {
                pso[0] = "1";
              }
              if (s.workarea[l].processingscheduleother == "1") {
                otherflag[0] = otherflag[0] + "1";
              } else {
                otherflag[0] = otherflag[0] + "1";
              }
            }
            //作業箇所
            packagemeisaiList.push({
              rowspan: 1,
              status: st[0],
              code: add[0],
              sepalate: sep[0],
              name: warea[0],
              color: bgcolor[0],
              display: disp[0],
              nextmonth: psm[0],
              hold: psh[0],
              nextcycle: psc[0],
              keyid: key[0],
              width: "workarea",
              refid: refid[0],
              suu: wareasuu[0],
              zansuu: wareazansuu[0],
              kbn: wareakbn[0],
              other: pso[0]
            });
            //残理由
            const rb = [""];
            if (c.remainreson == "1") {
              rb[0] = "病院側より要請";
            } else if (c.remainreson == "2") {
              rb[0] = "自社都合";
            } else if (c.remainreson == "3") {
              rb[0] = "病院側より要請・自社都合";
            }
            packagemeisaiList.push({
              rowspan: 1,
              status: c.remainreson,
              code: "",
              sepalate: "",
              name: rb[0],
              color: bgcolor[0],
              width: "common"
            });
            //}
            //次回予定
            const nm = [""];
            if (nextmonth[0] != "0") {
              nm[0] = "次月対応";
              if (nexthold[0] != "0") {
                nm[0] = nm[0] + ", 日程検討";
              }
              if (nextcycle[0] != "0") {
                nm[0] = nm[0] + ", 次サイクル対応";
              }
            } else if (nexthold[0] != "0") {
              nm[0] = "日程検討";
              if (nextcycle[0] != "0") {
                nm[0] = nm[0] + ", 次サイクル対応";
              }
            } else if (nextcycle[0] != "0") {
              nm[0] = "次サイクル対応";
            }
            if (pso[0] != "0") {
              nm[0] = "別スケジュール対応";
            }
            packagemeisaiList.push({
              rowspan: 1,
              status: nextmonth[0] + "," + nexthold[0] + "," + nextcycle[0],
              code: "",
              sepalate: "",
              name: nm[0],
              color: bgcolor[0],
              width: "common"
            });
            //次回予定日
            packagemeisaiList.push({
              rowspan: 0,
              status: c.nextkeyid,
              code: "",
              sepalate: "",
              name: c.nextsscheduledate,
              color: bgcolor[0],
              width: ""
            });

            //以下の内容は、画面には表示させないがデータ処理場必須のため渡す(必須:rowspan=0)
            //開始時刻
            if (stime.length > 1) {
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: stime[0] + ":" + stime[1],
                color: bgcolor[0],
                widhth: ""
              });
            } else {
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: "",
                color: bgcolor[0],
                widhth: ""
              });
            }
            //終了時刻
            if (etime.length > 1) {
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: etime[0] + ":" + etime[1],
                color: bgcolor[0],
                widhth: ""
              });
            } else {
              packagemeisaiList.push({
                rowspan: 0,
                status: "",
                code: "",
                sepalate: "",
                name: "",
                color: bgcolor[0],
                widhth: ""
              });
            }
            //作業担当者
            packagemeisaiList.push({
              rowspan: 0,
              status: "",
              code: wtanto[0],
              sepalate: "",
              name: wtantonm[0],
              color: bgcolor[0],
              refarence: "",
              widhth: ""
            });
            //担当者
            packagemeisaiList.push({
              rowspan: 0,
              status: "",
              code: tanto[0],
              sepalate: "",
              name: tantonm[0],
              color: bgcolor[0],
              widhth: ""
            });
            //備考
            packagemeisaiList.push({
              rowspan: 0,
              status: "",
              code: "",
              sepalate: "",
              name: c.biko,
              color: bgcolor[0],
              widhth: ""
            });
            //その他
            packagemeisaiList.push({
              rowspan: 0,
              addflag: s.addflag,
              sepalationflag: s.sepalationflag,
              referencemeisaiid: "",
              processingschedulenextmonth: "",
              processingschedulehold: "",
              processingschedulenextcycle: "",
              processingscheduleother: pso[0],
              color: bgcolor[0],
              widhth: "",
              weekday: c.weekday,
              ninsuu: c.ninsuu
            });
            // 検討期日
            if (c.scheduleMonth == null) {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: c.ternmoverflag,
                code: "",
                sepalate: "",
                name: c.schedulereviewdate,
                color: bgcolor[0],
                width: "common2"
              });
            } else {
              packagemeisaiList.push({
                rowspan: prows[0],
                status: c.ternmoverflag,
                code: "",
                sepalate: "",
                name: c.schedulereviewdate,
                color: bgcolor[0],
                width: "common2"
              });
            }
            // 責任者
            packagemeisaiList.push({
              rowspan: prows[0],
              status: rtantonm[0],
              code: rtanto[0],
              sepalate: "",
              name: rtantonm[0],
              color: bgcolor[0],
              refarence: "",
              widhth: "common"
            });

            //状態表示用
            //次月、日程検討
            const yoteiflag = ["0"];
            const packagekey = [];

            // c.type -- 0:予定日無し, 1:実績日無し, 2:仮予定日, 3:日程検討, 4:次月対応, 5:次回サイクル, 6:別日程
            if (c.type == 3 || c.type == 4 || c.type == 6) {
              yoteiflag[0] = "0";
            } else {
              packagekey[0] = c.keyid;
              if (c.type == 0 || c.type == 2) {
                yoteiflag[0] = "4";
              } else if (c.type == 1) {
                yoteiflag[0] = "3";
              } else if (c.type == 5) {
                yoteiflag[0] = "2";
              } else {
                // ここは、通らないはず
                yoteiflag[0] = "1";
              }
            }

            if (k == 0) {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: meisairowspan,
                yotei: yoteiflag[0],
                nextkeyid: c.nextkeyid,
                unfinished: true,
                keyId: packagekey[0],
                select: "0",
                ex: "",
                addReason: c.addReason,
                changeReason: c.changeReason,
                refflag: "",
                hospitalTanto: "",
                remainreson: "",
                scncflag: s.scncflag,
                changeFlag: c.changeFlag,
                hospitalremainbiko: c.hospitalremainbiko,
                tantoUpdateInfo: c.tantoUpdateInfo,
                tantoUpdateReason: c.tantoUpdateReason,
                point: false,
                bikoflag: yoteiflag[0] === "0" ? false : true
              });
            } else {
              packageList.push({
                index: j,
                rowindex: rowindex[0],
                hojinCd: affiliation.hojinCd,
                hospitalCd: affiliation.hospitalCd,
                packageNo: c.packageNo,
                packageMeisaiNo: s.packageMeisaiNo,
                packagemeisais: packagemeisaiList,
                rowspan: 0,
                yotei: yoteiflag[0],
                nextkeyid: c.nextkeyid,
                unfinished: true,
                keyId: packagekey[0],
                select: "0",
                ex: "",
                addReason: c.addReason,
                changeReason: c.changeReason,
                refflag: "",
                hospitalTanto: "",
                remainreson: "",
                scncflag: s.scncflag,
                changeFlag: c.changeFlag,
                hospitalremainbiko: c.hospitalremainbiko,
                tantoUpdateInfo: c.tantoUpdateInfo,
                tantoUpdateReason: c.tantoUpdateReason,
                point: false,
                bikoflag: yoteiflag[0] === "0" ? false : true
              });
            }
            rowindex[0] = rowindex[0] + 1;
          }

          packages.push(packageList);
          console.log("packages", this.packages);
        }

        const scounts = packages.length;

        this.affiliations.push({
          hojinCd: affiliation.hojinCd,
          hospitalCd: affiliation.hospitalCd,
          hospitalName: affiliation.hospitalName,
          index: i,
          packages: packages,
          scount: scounts
        });
      }

      //this.scount = this.packages.length;
      console.log("scount", this.scount);

      const schedule = schedules;
      console.log("affiliations", this.affiliations);

      return schedule;
    },
    async setUpdateData(pcks, aindex) {
      this.$loading();
      console.log("setUpdateData", pcks);
      const affilitations = this.affiliations;
      const schedules = this.schedule;

      this.affiliations = [];
      //this.packages = [];
      this.schedule = [];

      this.schedule = schedules;
      affilitations[aindex].packages = pcks;
      //this.affiliations[aindex].packages = pcks;
      this.affiliations = affilitations;
      //this.packages = pcks;

      this.$unloading();
    },
    async getData(api) {
      let data;
      switch (this.select) {
        case Selects.Me:
        case Selects.User:
        default: {
          if (api) {
            this.$loading();

            try {
              data = await this.getRecordByUser();
              // TODO 暫定対応:シフトあり、実績なしのデータを非表示
              const bodies = [];
              data.body.forEach(e => {
                if (e.recordid !== null) bodies.push(e);
              });
              data.body = bodies;
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getPrivateRostar();
          }
          break;
        }
        case Selects.Office:
          if (api) {
            this.$loading();
            try {
              data = await this.getRecordByOffice();
            } catch (e) {
              console.log(e);
              this.$error(e.message);
            } finally {
              this.$unloading();
            }
          } else {
            data = Dummy.getOfficeRostar();
          }
          break;
      }
      await this.setAttendanceDetails(data);
    },
    async getRecordByUser() {
      const targetMonth = await this.getTargetMonth();
      const param = {
        incode: this.user.code ? this.user.code : this.user,
        targetmonth: targetMonth
      };
      console.log("getRecordByUser", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query=" + encode);
    },
    async getRecordByOffice() {
      const param = {
        affiliationId: this.shozoku.code ? this.shozoku.code : this.shozoku,
        attendanceDateFrom: this.dateFrom,
        attendanceDateTo: this.dateTo
      };
      console.log("getRecordByOffice", param);
      const encode = encodeURI(JSON.stringify(param));
      return await this.$get(this.Paths.attendance, "query-office=" + encode);
    },
    async Schedulefix(aindex, pindex, pathparam, bikoflag) {
      console.log("fix", aindex + "-" + pindex);
      //console.log("fix-index", item.index);
      const packs = this.affiliations[aindex].packages[pindex];
      console.log(packs);

      const props = { args: packs, bikoflag: bikoflag };
      console.log(props);
      //item.employee = this.user.code ? this.user.code : this.user;
      const result = await this.$showDialog(ScheduleFixDialog, props);

      console.log("result", result);

      if (result) {
        const date = new Date(this.date);

        this.$loading();

        this.schedule = [];

        try {
          this.schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam
          );
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async Servicefix(aindex, pindex, pathparam, kbn) {
      console.log("fix", aindex + "-" + pindex);
      //console.log("fix-index", item.index);
      const packs = this.affiliations[aindex].packages[pindex];
      console.log(packs);

      const props = { args: packs };
      console.log(props);
      //item.employee = this.user.code ? this.user.code : this.user;
      const result = await this.$showDialog(ServiceRecord, props);

      console.log("result", result);

      if (result) {
        const date = new Date(this.date);

        this.schedule = [];

        this.$loading();

        try {
          this.schedule = await this.getShiftMonth(
            date.getFullYear(),
            date.getMonth() + 1,
            pathparam
          );
        } catch (e) {
          this.$error(e.message);
        } finally {
          this.$unloading();
        }
      }
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    },
    async add() {
      const aff = [];
      const pack = [];
      const mei = [];
      const date = new Date(this.date);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      for (let i = 0; i < 11; i++) {
        if (i == 0) {
          mei.push({
            rowspan: 0,
            status: year + "-" + ("00" + month).slice(-2),
            code: "",
            sepalate: "",
            name: ""
          });
        } else {
          mei.push({
            rowspan: 0,
            status: "",
            code: "",
            sepalate: "",
            name: ""
          });
        }
      }

      pack.push({
        hojinCd: "",
        hospitalCd: "",
        index: "",
        packageMeisaiNo: "",
        packageNo: "",
        packagemeisais: mei,
        rowindex: "",
        rowspan: ""
      });

      aff.push(pack);

      const result = await this.$showDialog(ScheduleFixDialog, aff);

      if (result) {
        this.schedule = await this.getShiftMonth(
          date.getFullYear(),
          date.getMonth() + 1,
          ""
        );
      }
    }
  },
  async created() {
    console.log("params", this.$route.params);
    this.auth = this.$store.state.authority;
    this.$loading();

    try {
      if (this.$route.params.noschedule != undefined) {
        this.noschedule = this.$route.params.noschedule == "1" ? true : false;
        this.hold = this.$route.params.holdschedule == "1" ? true : false;
        this.nextmonth = this.$route.params.nextmonth == "1" ? true : false;
        this.nextcycle = this.$route.params.nextcycle == "1" ? true : false;
        this.otherwork = this.$route.params.otherschedule == "1" ? true : false;
        this.noexecution = this.$route.params.pattern == "1" ? true : false;
        this.schedule = await this.getShiftMonth("01001", "2022-01", "query");
      } else {
        this.noschedule = true;
        this.hold = true;
        this.nextmonth = true;
        this.nextcycle = true;
        this.otherwork = true;
        this.noexecution = true;
        /*if (this.auth == 2) {
          this.noschedule = false;
          this.hold = false;
          this.nextmonth = false;
          this.nextcycle = false;
          this.otherwork = false;
        }*/
      }
      /*this.schedule = await this.getShiftMonth("01001", "2022-01", "query");
      console.log("schedule", this.schedule);
      this.tableTitle = this.format;*/
    } catch (e) {
      this.$error(e.message);
    } finally {
      this.$unloading();
    }
  },
  mounted() {
    console.log("mounted");
  }
};
</script>

<style></style>

<style lang="scss" scoped>
.office {
  table {
    //width: 99%;
    border-collapse: collapse;
    display: block;
  }
}
.common {
  width: 7%;
}
.common2 {
  width: 7%;
}
.workarea {
  width: 27%;
}
.bold-text {
  font-weight: bold;
}
.pulldown-z-index {
  z-index: 3;
}
.fixed {
  position: sticky;
  left: 0;
  color: #333;
  background-color: white;
  z-index: 2;
  &:before {
    content: "";
    position: absolute;
    top: -1px;
    left: -1px;
    width: 100%;
    height: 100%;
  }
}
.private {
  table {
    // border-collapse: collapse;
    width: 100%;
  }
  // td {
  //   height: 80px;
  // }
}
.person {
  width: 50px;
}
.person::after {
  content: "ms";
}
.shift-event {
  font-size: 12px;
}

.warning-col {
  background-color: #ffcc66;
}

.worktime-span {
  float: right;
  margin-right: 15px;
}
</style>
