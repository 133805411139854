export default class PackageinfoResponse {
  hedder: Array<{
    hojinCd: string;
    hospitalCd: string;
    hospitalNm: string;
    yearMonth: string;
    details: Array<{
      packageNo: string;
      startTime: string;
      endTime: string;
      worktanto: Array<{
        userid: string;
        username: string;
        starttime: string;
        endtime: string;
        worktime: string;
        restTime: string;
      }>;
      tanto: Array<{
        userid: string;
        username: string;
        starttime: string;
        endtime: string;
        worktime: string;
        resttime: string;
      }>;
      packagemeisai: Array<{
        packageMeisaiNo: string;
        workpattern: string;
        area: string;
        floor: string;
        workarea: Array<{
          workarea: string;
          sepalationflag: string;
          displayflag: string;
          status: string;
          addflag: string;
          referenceworkareaid: string;
          processingschedulenextmonth: string;
          processingschedulehold: string;
          processingschedulenextcycle: string;
        }>;
        addflag: string;
        sepalationflag: string;
        referencemeisaiid: string;
      }>;
      biko: string;
      scheduledate: string;
      executiondate: string;
      addplaceflag: string;
      addplace: string;
      remainflag: string;
      remainplace: string;
      remainreson: string;
      remainbiko: string;
    }>;
    loginId: string;
  }>;
  constructor(hedder = []) {
    this.hedder = hedder;
  }
}
