<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col>
          <v-btn
            class=""
            color="success"
            v-if="!isVisibleConfirm()"
            @click="spotsearch()"
            >スポット参照</v-btn
          >
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="hospitalNm"
            v-if="isVisibleConfirm()"
            label="物件名"
            outlined
            prepend-inner-icon="mdi-hospital-building"
            disabled
          ></v-text-field>
          <v-autocomplete
            v-model="hospitalNm"
            :items="hospitals"
            v-if="!isVisibleConfirm()"
            outlined
            label="物件名"
            item-text="name"
            item-value="code"
            prepend-inner-icon="mdi-hospital-building"
            chips
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <DatePicker
            v-model="date"
            prepend-inner-icon="mdi-calendar"
            outlined
            label="作業予定日付"
            clearable
        /></v-col>
        <v-col class="dialog-padding">
          <label>開始時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="startTime"
            placeholder="開始時間"
            manual-input
            auto-scroll
            input-width="95%"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="dialog-padding">
          <label>終了時間</label>
          <vue-timepicker
            class="ml-7"
            v-model="endTime"
            placeholder="終了時間"
            manual-input
            auto-scroll
            input-width="95%"
          >
            <template v-slot:clearButton>
              <img src="@/assets/Clear.png" />
            </template>
          </vue-timepicker>
        </v-col>
        <v-col class="dialog-padding" cols="1">
          <v-checkbox v-model="timechange" label="追加・修正"> </v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="dialog-padding">
          <v-text-field
            v-model="weekday"
            label="曜日備考"
            outlined
            disabled
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model="ninsuu"
            label="パッケージ人数"
            outlined
            suffix="人"
            disabled
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row" v-for="(item, i) in datelist" :key="i">
        <v-col class="dialog-padding" cols="3">
          <DatePicker
            v-model="item.date"
            :label="'予定日  ' + (i + 2)"
            outlined
            prepend-inner-icon="mdi-calendar"
            clearable
          />
        </v-col>
        <v-btn class="" color="error" @click="clickDeldate(i)"
          >予定日削除</v-btn
        >
        <p></p>
        <p></p>
      </v-row>
      <v-col class="dialog-padding">
        <v-btn rounded color="primary" small @click="adddate()">
          <v-icon>mdi-plus</v-icon>予定日を追加</v-btn
        >
      </v-col>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-autocomplete
            v-model="mainuser"
            :items="users"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            prepend-inner-icon="mdi-account"
            label="作業担当者"
            item-text="name"
            item-value="code"
          ></v-autocomplete>
        </v-col>
        <v-col class="dialog-padding">
          <v-autocomplete
            v-model="user"
            :items="users"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            prepend-inner-icon="mdi-account"
            label="担当者"
            item-text="name"
            item-value="code"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="tantoUpdateInfo"
            label="担当者変更内容"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="tantoUpdateReason"
            label="担当者変更理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <template v-for="meisai in meisais">
        <v-card elevation="5" :key="meisai.packageMeisaiNo">
          <v-toolbar color="primary">
            <v-btn class="" color="error" @click="meisaidel(meisai.index)"
              >明細削除</v-btn
            >
            <v-btn
              class="mx-2"
              color="success"
              @click="checkchange(meisai.index, true)"
              >作業を全て対象にする</v-btn
            >
            <v-btn
              class="mx-2"
              color="warning"
              @click="checkchange(meisai.index, false)"
              >作業を全て未対象にする</v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2"
              color="success"
              @click="othercheckchange(meisai.index, true)"
              >全件別作業</v-btn
            >
            <v-btn
              class="mx-2"
              color="warning"
              @click="othercheckchange(meisai.index, false)"
              >全件通常作業</v-btn
            >
          </v-toolbar>
          <p></p>
          <v-row class="margin-row">
            <v-col class="dialog-padding">
              <v-text-field
                v-model="meisai.workPattern"
                label="作業内容"
                outlined
                prepend-inner-icon="mdi-toolbox"
                :rules="[Rules.Required]"
                maxlength="255"
              ></v-text-field>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox v-model="meisai.sepalationflag" label="追加・修正">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row class="margin-row">
            <v-col class="dialog-padding">
              <v-text-field
                v-model="meisai.floor"
                label="フロア"
                outlined
                prepend-inner-icon="mdi-floor-plan"
              ></v-text-field>
            </v-col>
            <v-col class="dialog-padding">
              <v-text-field
                v-model="meisai.area"
                label="エリア"
                outlined
                prepend-inner-icon="mdi-account"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            class="margin-row"
            v-for="(item, j) in meisai.workareas"
            :key="j"
          >
            <v-col class="dialog-padding" cols="3">
              <v-text-field
                v-model="item.workarea"
                :label="'作業箇所  ' + (j + 1)"
                outlined
                prepend-icon="mdi-close"
                @click:prepend="clickDelRow(meisai.packageMeisaiNo, j)"
                required
                :rules="[Rules.Required]"
                maxlength="255"
              />
            </v-col>
            <v-col class="dialog-padding" cols="2">
              <v-text-field
                v-model="item.workareasuu"
                :label="'数量 ' + (j + 1)"
                type="number"
                outlined
                prepend-icon="mdi-numeric"
              />
            </v-col>
            <v-col class="dialog-padding" cols="2">
              <v-autocomplete
                v-model="item.suukbn"
                :items="suuKbns"
                :label="'数量種類 ' + (j + 1)"
                :disabled="!item.add"
                clearable
                outlined
                item-text="name"
                item-value="code"
                prepend-icon="mdi-format-list-bulleted"
                return-object
              >
              </v-autocomplete>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox v-model="item.status" label="作業対象"> </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox v-model="item.other" label="別作業"> </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="2">
              <v-checkbox v-model="item.nextcycle" label="次回サイクル対応">
              </v-checkbox>
            </v-col>
            <v-col class="dialog-padding" cols="1">
              <v-checkbox v-model="item.sepalate" label="追加・修正">
              </v-checkbox>
            </v-col>
          </v-row>
          <v-col class="dialog-padding">
            <v-btn rounded color="primary" small @click="addrow(meisai)">
              <v-icon>mdi-plus</v-icon>作業箇所を追加</v-btn
            >
          </v-col>
          <p></p>
          <p></p>
        </v-card>
      </template>
      <p></p>
      <v-col class="dialog-padding">
        <v-btn rounded color="primary" small @click="addmeisai()">
          <v-icon>mdi-plus</v-icon>明細を追加</v-btn
        >
      </v-col>
      <v-row class="margin-row">
        <v-col class="dialog-padding" cols="3">
          <v-checkbox v-model="request" label="病院側より要請"> </v-checkbox>
          <v-text-field
            v-model="hospitalTanto"
            label="病院担当者"
            outlined
            prepend-icon="mdi-card-text"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="hospitalremainbiko"
            label="病院側残理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="addReason"
            label="追加理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="changeReason"
            label="変更理由"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="biko"
            label="備考"
            outlined
            prepend-icon="mdi-message-processing"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import PackageinfoResponse from "@/models/PackageinfoResponse";
import SpotreferecnceDialog from "./SpotreferecnceDialog.vue";
import { CODE_GROUP as CodeGroup, NOT_SET_CODE as NotSetCode } from "@/defines";

const Shozokus = [{ name: "", code: "" }];
const Shifts = [];

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsMeal = {
  Default: 0,
  Eat: 1
};
export default {
  name: "AttendanceFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],
  components: {
    "vue-timepicker": VueTimepicker
    //VueGoodTable
  },
  computed: {
    Title() {
      return "予定編集";
      /*const args = this.args;
      if (args) return "勤務実績追加・修正";
      const date = new Date(args.date);
      return args.name
        ? args.name
        : `${date.getMonth() + 1} 月 ${date.getDate()} 日`; //args.date;*/
    }
  },
  data() {
    return {
      date: "",
      data: {},
      meisais: [],
      targetYm: "",
      hospitals: [],
      addmode: true,
      keyId: "",
      insertflag: "0",
      select: "0",
      datelist: [],
      hospitalNm: "",
      weekday: "",
      ninsuu: 0,
      startTime: "",
      endTime: "",
      users: [],
      mainuser: "",
      user: "",
      biko: "",
      addReason: "",
      changeReason: "",
      request: false,
      hospitalTanto: "",
      timechange: false,
      hospitalremainbiko: "",
      tantoUpdateInfo: "",
      tantoUpdateReason: "",
      suukbn: { code: "", name: "" },
      suukbns: [],
      args: []
    };
  },
  methods: {
    adddate() {
      console.log("adddate_before", this.datelist);
      this.datelist.push({
        date: "",
        refkey: ""
      });
      console.log("adddate_after", this.datelist);
    },
    addrow(item) {
      console.log("addrow", item);
      item.workareas.push({
        workarea: "",
        workareasuu: "",
        suukbn: "",
        status: true,
        add: true,
        sepalate: true
      });
      //this.workareas.push({ workarea: "", status: false });
    },
    addmeisai() {
      const addmei = [];
      const wkpat = [""];
      const pacmeiNo = [1];
      if (this.meisais.length > 0) {
        wkpat[0] = this.meisais[this.meisais.length - 1].workPattern;
        pacmeiNo[0] =
          parseInt(this.meisais[this.meisais.length - 1].packageMeisaiNo) + 1;
      }

      // const pacmeiNo = this.meisais.length + 1;
      /*if (this.meisais.length > 0) {
        
      }*/

      addmei.push({
        worakarea: "",
        keyid: "",
        status: true,
        add: true,
        sepalate: true,
        nextcycle: false,
        other: false,
        referenceworkareaid: "",
        workareaSuu: "",
        workareakbn: ""
      });
      this.meisais.push({
        packageMeisaiNo: pacmeiNo[0],
        workPattern: wkpat[0],
        floor: "",
        area: "",
        workareas: addmei,
        addflag: "1",
        sepalationflag: "1",
        referencemeisaiid: "",
        processingschedulenextmonth: "",
        processingschedulehold: "",
        processingschedulenextcycle: "",
        meisaiadd: true,
        index: pacmeiNo[0]
      });
    },
    async spotsearch() {
      //スポットの場合に過去に登録されたスポットの明細を取得します。
      console.log("spot", "spotsearch");
      console.log("Hp", this.hospitalNm);
      const packs = [];
      packs.push({
        schedule: "",
        affiliations: this.hospitalNm
      });
      console.log(packs);

      const props = { args: packs };
      console.log(props);

      const result = await this.$showDialog(SpotreferecnceDialog, props);

      //if (result) {
      //const args = result.args;

      this.args = result.args;

      await this.setDisplay(result.args, true);
      //}
    },
    async clickDeldate(i) {
      console.log("deldate");
      console.log("row", i);
      console.log("datelist", this.datelist);

      if (this.datelist[i].refkey != "") {
        const msg = `予定日 ${i +
          2} は、実績と紐づいているため削除できません。`;
        return;
      }

      if (this.datelist[i].date != "") {
        const msg = `予定日 ${i + 2} を削除します。よろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
      }

      this.datelist.splice(i, 1);
    },
    async clickDelRow(No, i) {
      const meisais = this.meisais;
      console.log("meisais", meisais);
      console.log("No", No);
      const absNo = [0];

      for (let j = 0; j < meisais.length; j++) {
        if (meisais[j].packageMeisaiNo == Math.abs(No)) {
          absNo[0] = j;
          break;
        }
      }
      //if (meisais[absNo - 1].workareas.length > 1) {
      if (meisais[absNo[0]].workareas[i].add == true) {
        if (meisais[absNo[0]].workareas[i].workarea) {
          const msg = `作業箇所 ${i + 1} を削除します。よろしいですか？`;
          if (!(await this.$deleteConfirm(msg))) return;
        }
        console.log("RowDelete", meisais[absNo[0]].workareas);
        this.meisais = meisais;
        this.meisais[absNo[0]].workareas.splice(i, 1);
      } else {
        const msg = "追加分で無いため削除できません。";
        this.$error(msg);
      }
      //}
    },
    async meisaidel(packageNo) {
      const absNo = [0];
      const meisais = this.meisais;
      //追加した明細だけを削除する。
      for (let i = 0; i < meisais.length; i++) {
        if (meisais[i].index == Math.abs(packageNo)) {
          absNo[0] = i;
          break;
        }
      }
      if (meisais[absNo[0]].addflag == "1") {
        const msg = `明細 ${absNo[0] + 1} を削除します。よろしいですか？`;
        if (!(await this.$deleteConfirm(msg))) return;
        this.meisais.splice(absNo[0], 1);
      } else {
        const msg = "追加分で無いため削除できません。";
        this.$error(msg);
      }
    },
    async checkchange(meisaiNo, flag) {
      const absNo = [0];
      const meisais = this.meisais;
      for (let i = 0; i < meisais.length; i++) {
        if (meisais[i].index == Math.abs(meisaiNo)) {
          absNo[0] = i;
          break;
        }
      }
      console.log("this.meisais[absNo[0]]", this.meisais[absNo[0]]);
      //明細内の値をチェックと同じように変更します。
      for (let i = 0; i < this.meisais[absNo].workareas.length; i++) {
        this.meisais[absNo[0]].workareas[i].status = flag;
      }
    },
    async othercheckchange(meisaiNo, flag) {
      const absNo = [0];
      const meisais = this.meisais;
      for (let i = 0; i < meisais.length; i++) {
        if (meisais[i].index == Math.abs(meisaiNo)) {
          absNo[0] = i;
          break;
        }
      }
      console.log("this.meisais[absNo[0]]", this.meisais[absNo[0]]);
      //明細内の値をチェックと同じように変更します。
      for (let j = 0; j < this.meisais[absNo[0]].workareas.length; j++) {
        this.meisais[absNo[0]].workareas[j].other = flag;
      }
    },
    shozokuSelect() {
      console.log("selected", this.shozoku);
      const shozoku = this.shozoku;
      const filtershifts = this.recordShifts.filter(
        e => e.refcode === shozoku || e.refcode === CommonShiftRefCode
      );
      this.shifts = filtershifts;
    },
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        console.log(set);
        if (!this.edit) {
          this.data.start = set.option1;
          this.data.end = set.option2;
          this.data.breaktime = set.option3;
        }
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "0";
        }
      }
    },
    isVisibleConfirm() {
      if (this.addmode == true) {
        return false;
      } else {
        return true;
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");
      try {
        this.$loading();
        const args = this.args;

        console.log("args", args);
        console.log("meisais", this.meisais);
        const PackageValue = [];
        const PackageMeisaiValue = [];
        const addplace = [""];
        const dtlst = [];
        const addchangeflag = ["0"]; // 追加・変更のチェックフラグ

        console.log("Hp", this.hospitalNm);

        //物件の入力チェック
        if (this.hospitalNm == undefined || this.hospitalNm == "") {
          this.$error("物件を選択してください。");
          return;
        }

        //作業日の入力チェック
        /*if (this.date == undefined || this.date == "" || this.date == null) {
          this.$error("作業予定日を設定してください。");
          return;
        }*/
        console.log("addmode", this.addmode);
        //console.log("this.args[0].unfinished", args[0].unfinished);
        if (
          (this.addmode == true ||
            (args != undefined && this.args[0].unfinished == true)) &&
          (this.date == undefined || this.date == "" || this.date == null)
        ) {
          this.$error("作業予定日を設定してください。");
          return;
        }

        //作業予定日が入っていないが予定日２以降が設定されている場合
        if (this.date == undefined || this.date == "" || this.date == null) {
          if (this.datelist.length > 0) {
            for (let i = 0; i < this.datelist.length; i++) {
              const dt = this.datelist[i];
              if (dt.date != "") {
                this.$error(
                  "作業予定日の設定が無い場合は、他の予定日の設定もできません。"
                );
                return;
              }
            }
          }
        }

        //作業予定日2以降に空欄がないかチェック
        if (this.datelist.length > 0) {
          console.log(this.datelist);
          for (let i = 0; i < this.datelist.length; i++) {
            console.log(this.datelist[i]);
            //const dt = this.datelist[i];
            if (this.datelist[i].date == "") {
              this.$error("予定日" + (i + 2) + "が設定されていません。");
              return;
            } else {
              dtlst.push({
                scdate: this.datelist[i].date,
                refkey: this.datelist[i].refkey
              });
            }
          }
        }

        //明細行のチェック
        if (this.meisais.length == 0) {
          this.$error("明細は、１つ以上設定してください。");
          return;
        }

        const nextc = ["0"];
        const otherdayflag = ["0"]; // 別作業のチェックフラグ

        const remainplace = [""]; //残箇所の取得
        for (let i = 0; i < this.meisais.length; i++) {
          //作業箇所のリストを作成する。
          const meisai = this.meisais[i];
          const sepalateflag = ["0"];

          if (meisai.workPattern == undefined || meisai.workPattern == "") {
            this.$error(
              "明細番号" + (i + 1) + "の作業内容が入力されていません。"
            );
            return;
          }

          const Workarea = [];

          if (meisai.workareas.length == 0) {
            this.$error(
              "明細番号" + (i + 1) + "の作業箇所が１件も存在しません。"
            );
            return;
          }

          for (let j = 0; j < meisai.workareas.length; j++) {
            const wkarea = meisai.workareas[j];
            const sepalate = ["0"];
            const display = ["0"];
            const add = ["0"];
            const other = ["0"];
            const next = ["0"];
            if (wkarea.sepalate == true) {
              sepalate[0] = "1";
              if (addchangeflag[0] === "0") {
                addchangeflag[0] = "1";
              }
            }
            if (wkarea.status == true) {
              display[0] = "1";
            }
            if (wkarea.add == true) {
              add[0] = "1";
            }
            if (wkarea.other == true) {
              other[0] = "1";
              if (otherdayflag[0] === "0") {
                otherdayflag[0] = "1";
              }
            }
            if (wkarea.nextcycle == true) {
              //next[0] = "1";
              if (next[0] == "0") {
                next[0] = "1";
                if (nextc[0] == "0") {
                  nextc[0] = "1";
                }
              }
              //残箇所情報を作成
              if (remainplace[0] == "") {
                remainplace[0] = wkarea.workarea;
              } else {
                remainplace[0] = remainplace[0] + ", " + wkarea.workarea;
              }
            }
            if (wkarea.workarea == undefined || wkarea.workarea == "") {
              this.$error(
                "作業箇所" + (j + 1) + "の作業箇所に未入力があります。"
              );
              return;
            }
            //追加作業は、作業対象外にしないようにする。
            if (add[0] == "1" && display[0] == "0") {
              this.$error(
                "追加された作業箇所を作業対象から外すことは、できません。"
              );
              return;
            }
            //作業対象、別作業、次回サイクルは、いずれかを設定すること！！
            if (display[0] == "0" && other[0] == "0" && next[0] == "0") {
              this.$error(
                "作業対象、別作業、次回サイクル対応のいずれかを必ず選択してください。"
              );
              return;
            }
            if (
              (display[0] == "1" && (other[0] == "1" || next[0] == "1")) ||
              (other[0] == "1" && (display[0] == "1" || next[0] == "1")) ||
              (next[0] == "1" && (display[0] == "1" || other[0] == "1"))
            ) {
              this.$error(
                "作業対象、別作業、次回サイクル対応は、１つから選択してください。"
              );
              return;
            }

            // 数量と数量種類は、対となる
            console.log("check_suryo");
            let suukbn = "";
            if (wkarea.suukbn != "") {
              if (wkarea.suukbn != null && wkarea.suukbn.code != undefined) {
                suukbn = wkarea.suukbn.code;
              } else if (wkarea.suukbn != null) {
                suukbn = wkarea.suukbn;
              } else {
                suukbn = "";
              }
            }
            if (
              wkarea.workareasuu != null &&
              wkarea.workareasuu != undefined &&
              wkarea.workareasuu != ""
            ) {
              // 数値入力がある場合は、数量種類の選択が必須
              if (suukbn == "") {
                this.$error(
                  "数量が入力されている場合は、数量種類を選択してください。"
                );
                return;
              } /*else if (wkarea.suukbn != "" && (wkarea.suukbn.code == undefined || wkarea.suukbn.code == null)) {
                this.$error(
                  "数量が入力されている場合は、数量種類を選択してください。"
                );
                return;
              }*/
            } else {
              // 数値入力がない場合は、選択不要
              if (suukbn != "") {
                this.$error(
                  "数量がない場合は、数量種類は選択しないでください。"
                );
                return;
              }
            }

            console.log("refkey", wkarea.keyid);

            Workarea.push({
              workarea: wkarea.workarea,
              workareaSuu: wkarea.workareasuu,
              workareaSuuKbn: suukbn,
              sepalationflag: sepalate[0],
              displayflag: display[0],
              status: "0",
              addflag: add[0],
              referenceworkareaid: wkarea.referenceworkareaid,
              processingscheduleother: wkarea.other === true ? "1" : "0",
              processingschedulenextcycle: wkarea.nextcycle === true ? "1" : "0"
            });
          }

          /*if (
            (nextc[0] == "1" && rem == false) ||
            (otherdayflag[0] == "1" && rem == false) ||
            (nextc[0] == "0" && rem == true) ||
            (otherdayflag[0] == "0" && rem == true)
          ) {
            this.$error(
              "次回サイクルまたは別日程の場合は、病院側より要請を必ずセットで設定をしてください。"
            );
            return;
          }*/

          const meiadd = ["0"];
          if (meisai.meisaiadd == true) {
            meiadd[0] = "1";
          }

          if (meisai.sepalationflag == true) {
            sepalateflag[0] = "1";
            if (addchangeflag[0] === "0") {
              addchangeflag[0] = "1";
            }
          }

          PackageMeisaiValue.push({
            packageMeisaiNo: meisai.packageMeisaiNo,
            workpattern: meisai.workPattern,
            area: meisai.area,
            floor: meisai.floor,
            workarea: Workarea,
            addflag: meiadd[0],
            sepalationflag: sepalateflag[0],
            referencemeisaiid: "",
            processingschedulenextmonth: "",
            processingschedulehold: "",
            processingschedulenextcycle: "",
            meisaiadd: meisai.meisaiadd
          });
        }

        //次回サイクルの場合は、病院側からの要請かつ担当者を必須入力
        const rem = this.request;
        const hpt = this.hospitalTanto;
        const msg = [""];
        console.log("n, r, h", nextc[0] + rem + hpt);
        if (nextc[0] == "1" && rem == false) {
          msg[0] =
            "別作業または次回サイクルがある場合は、病院側より要請を必ず設定してください。";
        } else if (nextc[0] == "0" && otherdayflag[0] == "0" && rem == true) {
          msg[0] =
            "病院側より要請がチェックされている場合は、別作業または次回サイクルを選択してください。";
        } else if (nextc[0] == "1" && rem == true) {
          msg[0] = "";
        } else if (otherdayflag[0] == "1" && rem == false) {
          msg[0] =
            "別作業または次回サイクルがある場合は、病院側より要請を必ず設定してください。";
        } else if (otherdayflag[0] == "1" && rem == true) {
          msg[0] = "";
        }
        if (msg[0] != "") {
          this.$error(msg[0]);
          return;
        }

        // 以下の条件の入力チェックを追加 2022/10/14
        // 追加・変更にチェックがついている場合は、追加理由 or 変更理由に入力必須
        if (addchangeflag[0] === "1") {
          if (
            (this.addReason == undefined || this.addReason == "") &&
            (this.changeReason == undefined || this.changeReason == "")
          ) {
            this.$error(
              "追加・変更にチェックが入っている場合は、追加理由または変更理由の入力が必要です。"
            );
            return;
          }
        }

        // 病院側の都合で残が発生(?)する場合は、理由を必須とする
        if (this.request === true) {
          if (
            this.hospitalremainbiko == undefined ||
            this.hospitalremainbiko == "" ||
            this.hospitalTanto == undefined ||
            this.hospitalTanto == ""
          ) {
            this.$error(
              "病院側より要請がある場合は、担当者と理由を必ず記載してください。"
            );
            return;
          }
        } else {
          if (this.hospitalTanto != "" || this.hospitalremainbiko != "") {
            this.$error(
              "病院側の担当者または理由を入力している場合は、病院側より要請を設定してください。"
            );
            return;
          }
        }

        // 別作業が１つでも存在かつ病院側の理由に入力が無い場合は、登録不可
        /*if (otherdayflag[0] === "1") {
          // 病院側の要請チェック、病院担当者、病院側残理由について記載を必須とする
          if (this.hospitalremainbiko == undefined || this.hospitalremainbiko == "" 
              || this.request == false || this.hospitalTanto == undefined || this.hospitalTanto == "") {
                this.$error(
                "別作業にした箇所がある場合は、病院側より要請・病院担当者・病院側残理由を記載してください。"
                );
                return;
          }
        }*/

        const addplflag = ["0"];

        if (addplace[0] !== "") {
          addplflag[0] = "1";
        }

        console.log("user", this.user);

        const wktanto = [];
        const tantochk = [];
        if (this.mainuser != undefined || this.mainuser != null) {
          for (let k = 0; k < this.mainuser.length; k++) {
            if (this.mainuser[k] == undefined) {
              continue;
            }
            if (tantochk.includes(this.mainuser[k])) {
              this.$error("作業担当者、担当者で重複が存在します。");
              return;
            }
            tantochk.push(this.mainuser[k]);
            wktanto.push({
              userid: this.mainuser[k],
              username: "",
              starttime: "",
              endtime: "",
              worktime: "",
              resttime: ""
            });
          }
        }

        const tanto = [];
        if (this.user != undefined || this.user != null) {
          for (let m = 0; m < this.user.length; m++) {
            if (this.user[m] == undefined) {
              continue;
            }
            if (tantochk.includes(this.user[m])) {
              this.$error("作業担当者、担当者で重複が存在します。");
              return;
            }
            tantochk.push(this.user[m]);
            tanto.push({
              userid: this.user[m],
              username: "",
              starttime: "",
              endtime: "",
              worktime: "",
              resttime: ""
            });
          }
        }

        const packNo = ["0"];
        const hjcd = [""];
        const hpcd = [""];
        const hpnm = [""];
        const ym = [""];
        const pointflg = ["0"];

        if (this.addmode == true) {
          packNo[0] = 0;
          hjcd[0] = this.hospitalNm.substr(0, 2);
          hpcd[0] = this.hospitalNm.substr(2, 3);
          ym[0] = this.date;
        } else if (this.args[0].unfinished == true) {
          packNo[0] = args[0].packageNo;
          hjcd[0] = args[0].hojinCd;
          hpcd[0] = args[0].hospitalCd;
          hpnm[0] = args[0].hospitalNm;
          ym[0] = this.date;
        } else if (this.select == "1") {
          packNo[0] = args[0].packageNo;
          hjcd[0] = args[0].hojinCd;
          hpcd[0] = args[0].hospitalCd;
          hpnm[0] = args[0].hospitalNm;
          ym[0] = this.targetYm;
          pointflg[0] = "1";
        } else {
          packNo[0] = args[0].packageNo;
          hjcd[0] = args[0].hojinCd;
          hpcd[0] = args[0].hospitalCd;
          hpnm[0] = args[0].hospitalNm;
          ym[0] = this.targetYm;
        }

        //残処理からの新規予定登録処理用
        const serial = [null];
        if (this.meisais[0].workareas[0].keyid != undefined) {
          const keylst = this.meisais[0].workareas[0].keyid.split("_");
          //キーの5つめに連番情報がある
          serial[0] = keylst[4];
        }

        const sttime = [""];
        if (this.startTime != null) {
          if (this.startTime.HH == undefined) {
            sttime[0] = this.startTime;
          } else {
            sttime[0] = this.startTime.HH + ":" + this.startTime.mm;
          }
        }

        const endtime = [""];
        if (this.endTime != null) {
          if (this.endTime.HH == undefined) {
            endtime[0] = this.endTime;
          } else {
            endtime[0] = this.endTime.HH + ":" + this.endTime.mm;
          }
        }

        PackageValue.push({
          packageNo: packNo[0],
          startTime: sttime[0],
          endTime: endtime[0],
          worktanto: wktanto,
          tanto: tanto,
          packagemeisai: PackageMeisaiValue,
          biko: this.biko,
          scheduledate: this.date,
          executiondate: "",
          addplaceflag: addplflag[0],
          addplace: addplace[0],
          addReason: this.addReason,
          changeReason: this.changeReason,
          remainflag: "",
          remainplace: remainplace[0],
          remainreson: this.request == true ? "1" : "0",
          remainbiko: null,
          assemblytime: null,
          executionstarttime: null,
          executionendtime: null,
          leavetime: null,
          keyid: this.keyId,
          serialno: serial[0],
          insert: this.insertflag,
          pointflg: pointflg[0],
          datelist: dtlst,
          hospitalTanto: this.hospitalTanto,
          changeFlag: this.timechange == true ? "1" : "0",
          hospitalremainbiko: this.hospitalremainbiko,
          tantoUpdateInfo: this.tantoUpdateInfo,
          tantoUpdateReason: this.tantoUpdateReason
        });

        const Packagehed = [];
        Packagehed.push({
          hojinCd: hjcd[0],
          hospitalCd: hpcd[0],
          hospitalNm: hpnm[0],
          yearMonth: ym[0],
          details: PackageValue,
          loginId: this.$store.state.userincode
        });

        const param = new PackageinfoResponse(Packagehed);
        const result = await this.$post(this.Paths.schedulefix, param);

        console.log("result", result);

        //if (result) {
        await this.$info("更新しました。", "予定");
        console.log("complete end");
        this.$close(true);
        //}
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getkeydatelst(key, unfinishedflag) {
      const param = {
        keyid: key
      };
      const encode = encodeURI(JSON.stringify(param));

      const result = await this.$get(
        this.Paths.scheduledate,
        "query=" + encode
      );

      console.log(result);

      if (result != null && result.dateList != null) {
        if (result.dateList.length > 0) {
          this.datelist = [];
          for (let i = 0; i < result.dateList.length; i++) {
            const sdate = result.dateList[i].date.split("/");
            if (i == 0 && unfinishedflag == true) {
              this.date = sdate[0] + "-" + sdate[1] + "-" + sdate[2];
            } else {
              this.datelist.push({
                date: sdate[0] + "-" + sdate[1] + "-" + sdate[2],
                refkey: result.dateList[i].refkey
              });
            }
          }
        }
      }

      return;
    },
    async getUsers() {
      console.log("getUsers");
      return await this.$get(this.Paths.shainFilter);
    },
    async getHospital() {
      console.log("getHospital");
      return await this.$get(this.Paths.hpFilter);
    },
    async getCodeMasterItems() {
      console.log("getExist");
      this.codeMasterItems = await this.$get(this.Paths.codeMasterPulldown);
    },
    getPullDownItems() {
      console.log("getPullDownItems");
      let f = [];
      f = this.codeMasterItems.filter(e => e.refcode === CodeGroup.SUUKBN);
      this.suuKbns = f.filter(e => e.code !== NotSetCode.SUUKBN);
    },
    async setDisplay(args, addmode) {
      if (args == undefined) {
        return;
      }
      //Schedule[物件名(0), 作業年月(1), 委託先(2), 作業内容(3), 作業日次(4), 開始時間(5), 終了時間(6), 作業担担当(7), 担当者(8), フロア(9), エリア(10), 作業箇所(11), 備考(12), その他(13)]
      //Unfinish[物件名(0), 作業年月(1), 作業内容(2), 作業予定日(3), フロア(4), エリア(5), 作業箇所(6), 残理由(7), 次回予定(8), 次回予定日(9), 開始時間(10), 終了時間(11), 作業担当(12), 担当(13), 備考(14), その他(15)]
      //ListNo[物件名(0), 作業内容(1), 作業日次(2), 開始時間(3), 終了時間(4), 作業担担当(5), 担当者(6), フロア(7), エリア(8), 作業箇所(9), 備考(10), その他(11)]
      const ListNo = [0];
      //呼出元に応じてセットする内容を変更
      //作業未了一覧からの遷移を考慮
      for (let i = 1; i < 12; i++) {
        let pushnum = i;
        if (args[0].unfinished != true || args[0].unfinished == undefined) {
          pushnum = i + 2;
          ListNo.push(pushnum);
        } else {
          let pushnum = i;
          if (i >= 3 && i <= 6) {
            pushnum = i + 7;
          } else if (i >= 7 && i <= 9) {
            pushnum = i - 3;
          } else if (i >= 10 && i <= 11) {
            pushnum = i + 4;
          } else if (i <= 2) {
            pushnum = i + 1;
          }
          ListNo.push(pushnum);
        }
      }

      this.meisais = [];

      const workrefkey = [""];

      const bikoflag = args[0].bikoflag;

      //const meisais = [args.length];
      //パッケージ明細の数だけ格納する。
      for (let i = 0; i < args.length; i++) {
        const row = args[i]; //パッケージ
        const mei = row.packagemeisais; //パッケージ明細
        const workadd = mei[ListNo[9]].code.split(","); //追加フラグ
        const sepalatef = mei[ListNo[9]].sepalate.split(","); //作業対象フラグ
        const key = mei[ListNo[9]].keyid.split(","); //パッケージキー
        const workareas = []; //作業箇所
        const workareaList = mei[ListNo[9]].name.split(","); //作業箇所を抽出
        const workareasuus = mei[ListNo[9]].suu.split(","); //作業箇所の数値
        const Workareakbns = mei[ListNo[9]].kbn.split(","); // 作業箇所の数値区分
        const nextc = mei[ListNo[9]].nextcycle.split(","); //次回サイクルフラグ

        // 未了一覧から遷移した場合と通常の場合で処理を分ける
        if (args[0].unfinished != true) {
          //const workdisp = mei[ListNo[9]].display.split(",");
          const workstatus = mei[ListNo[9]].status.split(","); //作業箇所の状態
          const referenceid = mei[ListNo[9]].referenceid.split(","); //参照キー
          const others = mei[ListNo[9]].other.split(","); //別作業フラグ
          for (let j = 0; j < workareaList.length; j++) {
            let check = false;
            let add = false;
            let sep = false;
            let id = "";
            let ref = "";
            let other = false;
            let nc = false;
            //各チェックボックスの状態を設定
            if (workstatus[j] === "1") {
              check = true;
            }
            if (workadd[j] == "1") {
              add = true;
            }
            if (sepalatef[j] == "1") {
              sep = true;
            }
            //キー情報の格納
            if (key[j] != null && key[j] != "null") {
              id = key[j];
              workrefkey[0] = id;
            }
            if (referenceid[j] != null && referenceid[j] != "null") {
              ref = referenceid[j];
            }
            if (others[j] == "1") {
              other = true;
            }
            if (nextc[j] == "1") {
              nc = true;
            }
            //作業箇所のリストに設定する
            workareas.push({
              workarea: workareaList[j],
              workareasuu: workareasuus[j],
              suukbn: Workareakbns[j],
              status: check,
              add: add,
              sepalate: sep,
              keyid: id,
              referenceworkareaid: ref,
              other: other,
              nextcycle: nc
            });
          }
        } else {
          const workstatus = mei[ListNo[9]].display.split(",");
          for (let j = 0; j < workareaList.length; j++) {
            let check = false;
            let add = false;
            let sep = false;
            let id = "";
            let other = false;
            const otherflag = mei[ListNo[9]].other.split(","); //作業対象フラグ
            if (workstatus[j] === "1") {
              check = true;
            } else if (args[0].yotei === "0") {
              //予定が0の時は、常に対処とする。
              check = true;
            }

            if (workadd[j] == "1") {
              add = true;
            }
            if (sepalatef[j] == "1") {
              sep = true;
            }
            if (key[j] != null && key[j] != "null") {
              id = key[j];
              workrefkey[0] = id;
            }
            if (otherflag[j] != null && otherflag[j] == true) {
              other = true;
            }
            workareas.push({
              workarea: workareaList[j],
              workareasuu: workareasuus[j],
              suukbn: Workareakbns[j],
              status: check,
              add: add,
              sepalate: sep,
              keyid: id,
              referenceworkareaid: id,
              nextcycle: false,
              other: other
            });
          }
        }

        /*let other = "0";
        if (mei[ListNo[11]].processingscheduleother === "1") {
          if (args[0].yotei === "0") {
            other = "0";
          } else {
            other = "1";
          }
        }*/

        //明細情報の設定
        this.meisais.push({
          packageMeisaiNo: row.packageMeisaiNo,
          workPattern: mei[ListNo[1]].name,
          floor: mei[ListNo[7]].name,
          area: mei[ListNo[8]].name,
          workareas: workareas,
          addflag: mei[ListNo[11]].addflag,
          sepalationflag: mei[ListNo[11]].sepalationflag === "1" ? true : false,
          referencemeisaiid: "",
          processingschedulenextmonth: "",
          processingschedulehold: "",
          processingschedulenextcycle: "",
          meisaiadd: mei[ListNo[11]].addflag,
          index: i
        });
      }

      const TEST = this.meisais;

      console.log("meisais", TEST);

      //物件名 (追加の場合は、選択にする)
      if (addmode == false) {
        this.hospitalNm = args[0].packagemeisais[ListNo[0]].name;
      } else {
        this.hospitalNm = args[0].hojinCd + args[0].hospitalCd;
      }

      //担当者情報
      const user = [];
      const user2 = [];
      const tantoList = args[0].packagemeisais[ListNo[5]].code.split(",");
      const tantoList2 = args[0].packagemeisais[ListNo[6]].code.split(",");

      if (
        tantoList.length > 1 ||
        (tantoList.length === 1 && tantoList[0] !== "")
      ) {
        for (let j = 0; j < tantoList.length; j++) {
          user.push(tantoList[j]); //担当者
        }
      }

      if (
        tantoList2.length > 1 ||
        (tantoList2.length === 1 && tantoList2[0] !== "")
      ) {
        for (let j = 0; j < tantoList2.length; j++) {
          user2.push(tantoList2[j]); //担当者
        }
      }

      this.mainuser = user;
      this.user = user2;

      //日付の初期値(呼出元が予定作成時のみ)
      if (args[0].unfinished != true && addmode == false) {
        if (args[0].packagemeisais[ListNo[2]].name != undefined) {
          if (args[0].packagemeisais[ListNo[2]].name != null) {
            const sdate = args[0].packagemeisais[ListNo[2]].name.split("/");
            if (sdate.length == 3) {
              this.date = sdate[0] + "-" + sdate[1] + "-" + sdate[2];
            } else {
              this.date = args[0].packagemeisais[ListNo[2]].name;
            }
          }
        }
      }

      // 開始、終了時間の設定
      this.startTime = args[0].packagemeisais[ListNo[3]].name;
      this.endTime = args[0].packagemeisais[ListNo[4]].name;

      //備考情報の設定 スポットでないかつ、予定の追加の場合は、処理対象外
      if (args[0].point == false && bikoflag == true) {
        this.biko = args[0].packagemeisais[ListNo[10]].name;
      } else {
        this.biko = "";
      }

      //週、人数の情報をセット
      this.weekday = args[0].packagemeisais[ListNo[11]].weekday;
      this.ninsuu = args[0].packagemeisais[ListNo[11]].ninsuu;

      //予定年月、キー情報のセット
      this.targetYm = args[0].packagemeisais[ListNo[0]].status;
      this.keyId = args[0].keyId;

      // 病院名情報からモードを切替
      if (this.hospitalNm != undefined && addmode == false) {
        if (this.hospitalNm != "") {
          this.addmode = false;
        }
      } else if (addmode == true) {
        this.addmode = true;
      }

      //予定情報から追加フラグの制御
      if (args[0].yotei == "0") {
        this.insertflag = "1";
      } else if (args[0].yotei == "9") {
        this.insertflag = "9";
      }

      // 予定管理画面からの処理か判定
      if (args[0].select != undefined && args[0].select != null) {
        this.select = args[0].select;
      }

      //追加理由をセット ※備考フラグ経っていない場合は、処理対象外
      if (
        args[0].addReason != undefined &&
        args[0].addReason != null &&
        bikoflag == true
      ) {
        this.addReason = args[0].addReason;
      }

      //変更理由をセット ※備考フラグ経っていない場合は、処理対象外
      if (
        args[0].changeReason != undefined &&
        args[0].changeReason != null &&
        bikoflag == true
      ) {
        this.changeReason = args[0].changeReason;
      }

      //残理由と病院担当者をセット ※備考フラグ経っていない場合は、処理対象外
      if (
        args[0].remainreson != undefined &&
        args[0].remainreson != null &&
        bikoflag == true
      ) {
        const res = Number(args[0].remainreson);
        switch (res) {
          case 1:
            this.request = true;
            break;
          case 3:
            this.request = true;
            break;
        }
      }

      // 病院担当者のセット ※備考フラグ経っていない場合は、処理対象外
      if (
        args[0].hospitalTanto != undefined &&
        args[0].hospitalTanto != null &&
        bikoflag == true
      ) {
        this.hospitalTanto = args[0].hospitalTanto;
      }

      // 担当者変更情報をセット ※備考フラグ経っていない場合は、処理対象外
      if (
        args[0].changeFlag != undefined &&
        args[0].changeFlag != null &&
        bikoflag == true
      ) {
        this.timechange = args[0].changeFlag == "1" ? true : false;
      }

      if (
        args[0].tantoUpdateInfo != null &&
        args[0].tantoUpdateInfo != undefined &&
        bikoflag == true
      ) {
        this.tantoUpdateInfo = args[0].tantoUpdateInfo;
      }
      if (
        args[0].tantoUpdateReason != null &&
        args[0].tantoUpdateReason != undefined &&
        bikoflag == true
      ) {
        this.tantoUpdateReason = args[0].tantoUpdateReason;
      }

      //未了一覧からの場合は、不要
      if (args[0].unfinished != true) {
        if (
          args[0].hospitalremainbiko != undefined &&
          args[0].hospitalremainbiko != null
        ) {
          this.hospitalremainbiko = args[0].hospitalremainbiko;
        }
      }

      //keyIDが存在する場合は、日付情報を取得する
      if (this.keyId != undefined && this.keyId != null && this.keyId != "") {
        await this.getkeydatelst(this.keyId, false);
      } else if (workrefkey[0] != "") {
        const KeyIdList = workrefkey[0].split("_");
        await this.getkeydatelst(
          KeyIdList[0] +
            "_" +
            KeyIdList[1] +
            "_" +
            KeyIdList[2] +
            "_" +
            KeyIdList[3] +
            "_" +
            KeyIdList[4],
          true
        );
      }
    }
  },
  async created() {
    this.meisais = [];
    console.log("created", this.args);
    //console.log("bikoflag", this.bikoflag);
    //const args = this.args;
    const users = await this.getUsers();
    this.hospitals = await this.getHospital();
    this.users = users;
    await this.getCodeMasterItems();
    this.getPullDownItems();

    await this.setDisplay(this.args, false);
  }
};
</script>

<style scoped>
label {
  font-size: 1em;
}
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
.card-padding {
  padding-top: 35px;
}
input[type="checkbox"] {
  transform: scale(2);
}
</style>
